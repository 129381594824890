import heroImage from "../assets/images/heroisit.svg";
import logoImage from "../assets/images/5.svg";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Slider } from "../components/ui/slider";
import {
  CheckCircle,
  Star,
  Users,
  Brain,
  TrendingUp,
  BarChart,
  ChevronDown,
  ChevronUp,
  Menu as MenuIcon,
  Check,
  MessageSquare,
} from "lucide-react";
import { Switch } from "../components/ui/switch";
import { supabase } from "../supabaseClient";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { SessionContext } from "../index";
import { cn } from "../lib/utils";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";

// Authentication footer component
const AuthFooter = ({ view, setView }) => {
  return (
    <div className="flex flex-col items-center gap-2 mt-4 text-sm text-gray-600">
      {view === "sign_in" && (
        <button
          onClick={() => setView("forgotten_password")}
          className="text-blue-600 hover:text-blue-800 font-medium"
        >
          Forgot your password?
        </button>
      )}
      {view === "sign_in" ? (
        <div>
          Don&apos;t have an account?{" "}
          <button
            onClick={() => setView("sign_up")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign up
          </button>
        </div>
      ) : view === "forgotten_password" ? (
        <div>
          Remember your password?{" "}
          <button
            onClick={() => setView("sign_in")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign in
          </button>
        </div>
      ) : (
        <div>
          Already have an account?{" "}
          <button
            onClick={() => setView("sign_in")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign in
          </button>
        </div>
      )}
    </div>
  );
};

// Determine redirection URL post-auth
const getRedirectUrl = () => {
  const isProd =
    window.location.hostname === "www.upangea.com" ||
    window.location.hostname === "upangea.com";
  return isProd
    ? "https://www.upangea.com/dashboard"
    : "http://localhost:3000/dashboard";
};

export default function LandingPage() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [openFAQ, setOpenFAQ] = useState(null);

  // CARS-Only Score State
  const [carsScore, setCarsScore] = useState(123);

  const [showSupabaseAuth, setShowSupabaseAuth] = useState(false);

  const navigate = useNavigate();
  const session = useContext(SessionContext);

  // Refs for scrolling
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const aboutRef = useRef(null);

  // Sample testimonials
  const testimonials = [
    {
      name: "Alex C.",
      score: "129 in CARS",
      quote:
        "Finally found something that actually works. Started at 123 and hit 129 after 3 months of consistent, focused practice. The instant feedback was a game-changer.",
    },
    {
      name: "Sarah L.",
      score: "130 in CARS",
      quote:
        "Tried big-name test prep companies, but their CARS felt off. UPangea's passages truly mimic AAMC style. I jumped from 125 to 130 faster than I ever expected.",
    },
    {
      name: "Michael T.",
      score: "128 in CARS",
      quote:
        "Timing was my nightmare—I always guessed on the last passage. Thanks to targeted drills here, I cut my passage time and boosted accuracy.",
    },
    {
      name: "Emily R.",
      score: "129 in CARS",
      quote:
        "So many prep programs half-ass CARS. UPangea stands out with uncanny authenticity. Plus, the AI tutor's feedback saved me from repeated mistakes.",
    },
  ];

  // Sample FAQ items
  const faqItems = [
    {
      question: "What's so special about your CARS passages?",
      answer:
        "Big-name test prep companies often 'guesstimate' CARS logic. We don’t. Our entire question bank is custom-built to replicate AAMC style, passages, and logic cues down to every subtlety.",
    },
    {
      question: "Why offer free beta access?",
      answer:
        "We're refining features based on real feedback. During this beta, all 10K+ AAMC-replica passages and AI tutoring are free to a limited number of pre-meds. Once we've hit capacity, new sign-ups may wait until the official launch.",
    },
    {
      question: "How is UPenguin more effective than regular tutors?",
      answer:
        "A Harvard study found AI tutors can double learning versus standard teaching—plus you can practice anytime. UPenguin never sleeps or charges $300/hour, making your improvement more consistent.",
    },
    {
      question: "What if I want more than 6 points improvement?",
      answer:
        "Students often see 6+ points with daily practice over a few months. But there's no upper limit—some push beyond, especially with ~50 daily CARS questions and consistent AI feedback.",
    },
  ];

  // Visibility checks for hero animations
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );
    if (heroRef.current) observer.observe(heroRef.current);
    return () => {
      if (heroRef.current) observer.unobserve(heroRef.current);
    };
  }, []);

  // CARS-only improvement logic
  const calculatePotentialImprovement = () => {
    // Example: up to 6 points in about 4 months with consistent practice
    const maxPossible = 132;
    const projected = carsScore + 6;
    return projected > maxPossible ? maxPossible : projected;
  };

  // Scrolling function
  const scrollTo = (elementRef) =>
    elementRef.current?.scrollIntoView({ behavior: "smooth" });

  // Auth & session
  const handleLogin = () => setShowSupabaseAuth("sign_in");
  const handleSignUp = () => setShowSupabaseAuth("sign_up");
  const handleLogout = () => supabase.auth.signOut();

  // Check session on mount
  useEffect(() => {
    if (session) navigate("/dashboard");
  }, [session]);

  // Listen for auth changes (for Supabase)
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = params.get("access_token");
    if (accessToken) {
      window.location.replace("/dashboard");
      return;
    }

    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) window.location.href = "http://localhost:3000/dashboard";
    };
    checkSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === "SIGNED_IN" || event === "SIGNED_UP")) {
        window.location.href = "http://localhost:3000/dashboard";
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleAuthChange = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        window.location.replace("/dashboard");
      }
    };
    handleAuthChange();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === "SIGNED_IN" || event === "SIGNED_UP")) {
        window.location.replace("/dashboard");
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  // Mobile Nav
  const renderMobileNav = () => (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="md:hidden p-0 w-13 h-13 aspect-square flex items-center justify-center"
        >
          <MenuIcon className="h-5 w-5 p-0" />
          <span className="sr-only">Toggle menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[300px] sm:w-[400px] px-0 py-8">
        <SheetHeader className="px-6 mb-6">
          <SheetTitle className="text-2xl">Menu</SheetTitle>
        </SheetHeader>
        <nav className="px-6">
          <div className="space-y-4">
            <button
              onClick={() => {
                scrollTo(aboutRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-2 py-2.5 text-base font-bold text-gray-700 hover:text-blue-600 transition-colors rounded-lg hover:bg-blue-50"
            >
              Why CARS?
            </button>
            <button
              onClick={() => {
                scrollTo(featuresRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-2 py-2.5 text-base font-medium text-gray-700 hover:text-blue-600 transition-colors rounded-lg hover:bg-blue-50"
            >
              Features
            </button>
            <button
              onClick={() => {
                scrollTo(pricingRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-2 py-2.5 text-base font-medium text-gray-700 hover:text-blue-600 transition-colors rounded-lg hover:bg-blue-50"
            >
              Future Pricing
            </button>
          </div>
          <div className="mt-8 space-y-3">
            {session ? (
              <>
                <Button
                  onClick={() => navigate("/dashboard")}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
                >
                  Dashboard
                </Button>
                <Button
                  onClick={handleLogout}
                  variant="outline"
                  className="w-full text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Log Out
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleLogin}
                  variant="outline"
                  className="w-full text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Log In
                </Button>
                <Button
                  onClick={handleSignUp}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
                >
                  Sign Up (Beta)
                </Button>
              </>
            )}
          </div>
        </nav>
      </SheetContent>
    </Sheet>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-16 md:h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img
            src={logoImage}
            alt="UPangea Logo"
            className="h-10 w-10 md:h-14 md:w-14"
          />
          <span className="ml-1 text-2xl md:text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
            UPangea
          </span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="ml-auto hidden md:flex gap-4 sm:gap-6 items-center">
          <button
            onClick={() => scrollTo(aboutRef)}
            className="text-base font-bold text-gray-700 hover:text-blue-600 transition-colors"
          >
            Why CARS?
          </button>
          <button
            onClick={() => scrollTo(featuresRef)}
            className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors"
          >
            Features
          </button>
          <button
            onClick={() => scrollTo(pricingRef)}
            className="text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors"
          >
            Future Pricing
          </button>
          {session ? (
            <div className="flex items-center space-x-4">
              <div className="hidden md:flex items-center space-x-2">
                <span className="text-sm font-medium text-gray-700">
                  Welcome,
                </span>
                <span className="text-sm font-semibold text-blue-600">
                  {session.user.name}
                </span>
              </div>
              <Button
                onClick={() => navigate("/dashboard")}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
              >
                Dashboard
              </Button>
              <Button
                onClick={handleLogout}
                variant="outline"
                className="text-blue-600 border-blue-600 hover:bg-blue-50"
              >
                Log Out
              </Button>
            </div>
          ) : (
            <>
              <Button
                onClick={handleLogin}
                variant="outline"
                className="text-blue-600 border-blue-600 hover:bg-blue-50"
              >
                Log In
              </Button>
              <Button
                onClick={handleSignUp}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
              >
                Sign Up (Beta)
              </Button>
            </>
          )}
        </nav>

        {/* Mobile Nav */}
        <div className="ml-auto md:hidden">{renderMobileNav()}</div>
      </header>

      {/* Authentication Modal */}
      <Dialog
        open={showSupabaseAuth !== false}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            const params = new URLSearchParams(window.location.search);
            const type = params.get("type");
            if (type === "recovery") return;
            setShowSupabaseAuth(false);
          }
        }}
      >
        <DialogContent className="sm:max-w-md bg-white p-0 rounded-lg overflow-hidden">
          <div className="bg-gradient-to-r from-blue-600 to-green-600 p-4 mx-2 mt-2">
            <div className="flex items-center justify-center mb-4">
              <img src={logoImage} alt="UPangea Logo" className="h-12 w-12" />
              <span className="ml-2 text-3xl font-bold text-white">
                UPangea
              </span>
            </div>
            <h2 className="text-xl text-center text-white font-medium">
              {showSupabaseAuth === "sign_up"
                ? "Join Our Beta for CARS Mastery"
                : "Welcome Back"}
            </h2>
          </div>
          <div className="px-6 py-0">
            <Auth
              supabaseClient={supabase}
              view={showSupabaseAuth}
              appearance={{
                theme: ThemeSupa,
                extend: true,
                className: {
                  container: "space-y-0",
                  button: cn(
                    "bg-gradient-to-r from-blue-600 to-green-600",
                    "hover:from-blue-700 hover:to-green-700",
                    "text-white"
                  ),
                  divider: "hidden",
                  container_button: "hidden",
                  button_provider: "hidden",
                  provider: "hidden",
                },
                style: {
                  anchor: { display: "none" },
                  button: {
                    color: "white",
                    fontWeight: "500",
                  },
                },
              }}
              providers={["google"]}
              redirectTo={getRedirectUrl()}
            />
            <AuthFooter
              view={showSupabaseAuth}
              setView={(newView) => {
                setShowSupabaseAuth(newView);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>

      <main className="flex-1 pt-16 md:pt-0">
        {/* Hero Section */}
        <section
          ref={heroRef}
          className={`w-full py-8 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-br from-blue-600 to-green-600 transition-all duration-1000 ${
            isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}
        >
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-12">
              <div className="space-y-6 text-center lg:text-left lg:w-1/2">
                <div className="space-y-2">
                  <span className="inline-block px-3 py-1 text-sm font-semibold bg-white/10 text-white rounded-full backdrop-blur-sm">
                    Beta: Real AAMC-Style CARS, Free for Now
                  </span>
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl lg:text-7xl">
                    <span className="block"> Finally, Authentic MCAT CARS</span>
                    <span className="block text-blue-200"> For Better Practice</span>
                  </h1>
                </div>
                <p className="text-lg md:text-xl text-blue-100 max-w-[500px] mx-auto lg:mx-0">
                  Tired of unnrepresentative passages from test prep giants? We’re 
                  the only platform laser-focused on authentic CARS replication. 
                  Our 10K+ question bank and 24/7 AI tutor are <strong>free</strong>, 
                  but hurry-limited spots remain. 
                </p>
                <div className="flex flex-col sm:flex-row gap-4 mt-8 justify-center lg:justify-start">
                  <Button
                    size="lg"
                    variant="white"
                    className="w-full sm:w-auto shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 font-bold"
                    onClick={handleSignUp}
                  >
                    Claim Your Free Access →
                  </Button>
                  <Button
                    size="lg"
                    variant="outline"
                    className="w-full sm:w-auto text-white border-white hover:bg-white/10 transition-all duration-300"
                    onClick={() => scrollTo(featuresRef)}
                  >
                    See Features
                  </Button>
                </div>
                <div className="flex items-center justify-center lg:justify-start space-x-6 text-sm text-blue-100 mt-4 sm:mt-6">
                  <div className="flex items-center">
                    <Check className="w-4 h-4 mr-2" />
                    <span>Exact AAMC Q-Style</span>
                  </div>
                  <div className="flex items-center">
                    <Check className="w-4 h-4 mr-2" />
                    <span>AI Tutor (Harvard-Proven)</span>
                  </div>
                  <div className="flex items-center">
                    <Check className="w-4 h-4 mr-2" />
                    <span>Limited Free Beta</span>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 relative">
                <div className="w-full h-full bg-gradient-to-br from-blue-600/50 to-green-600/50 rounded-2xl shadow-2xl overflow-hidden transition-all duration-500 transform hover:scale-105 hover:rotate-2 backdrop-blur-sm">
                  <img
                    src={heroImage}
                    alt="MCAT Preparation"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="absolute -bottom-4 -right-4 bg-white p-4 rounded-lg shadow-lg">
                  <p className="text-blue-600 font-bold">
                    10,000+ Authentic Questions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Us Section */}
        <section
          ref={aboutRef}
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-200 via-white to-green-200 relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-grid-black/[0.02] bg-[size:30px_30px]" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/50 to-transparent" />

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="max-w-3xl mx-auto text-center mb-12">
              <span className="inline-block px-3 py-1 text-sm font-bold bg-blue-100 text-blue-600 rounded-full border border-blue-200 mb-4">
                Finally, Real CARS
              </span>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                Why True AAMC Replica CARS Matters
              </h2>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed mb-8">
                CARS is notorious for its subtle logic traps, rhetorical twists, 
                and precisely structured passages—yet most prep companies cut 
                corners. At UPangea, we replicate every nuance of AAMC design, 
                so you face the real thing before test day, not some halfway 
                clone.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:transform hover:-translate-y-1 border border-blue-100">
                <div className="h-12 w-12 rounded-lg bg-gradient-to-br from-blue-500 to-green-500 flex items-center justify-center mb-4">
                  <Brain className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-900">
                  Hyper-Focused on CARS
                </h3>
                <p className="text-gray-600">
                  We don’t scatter our efforts across the entire MCAT. 
                  All research, development, and updates revolve around 
                  building (and continually refining) AAMC-style CARS passages.
                </p>
              </div>

              <div className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:transform hover:-translate-y-1 border border-blue-100">
                <div className="h-12 w-12 rounded-lg bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center mb-4">
                  <MessageSquare className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-900">
                  2x Faster Learning
                </h3>
                <p className="text-gray-600">
                  A Harvard study showed AI tutors can double learning gains 
                  vs. standard teaching. Our 24/7 AI—UPenguin—lets you pinpoint 
                  errors, refine strategy, and move on, all without scheduling 
                  constraints.
                </p>
              </div>

              <div className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:transform hover:-translate-y-1 border border-blue-100">
                <div className="h-12 w-12 rounded-lg bg-gradient-to-br from-orange-500 to-red-500 flex items-center justify-center mb-4">
                  <Star className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3 text-gray-900">
                  Beta Powered by Stanford
                </h3>
                <p className="text-gray-600">
                  Word-of-mouth at Stanford’s pre-med community launched our 
                  beta. Feedback from top-tier students helps ensure our 
                  CARS passages remain the most realistic in the industry.
                </p>
              </div>
            </div>

            <div className="mt-16 max-w-2xl mx-auto text-center">
              <blockquote className="text-xl md:text-2xl font-medium text-gray-800 italic mb-6">
                &ldquo;Stop settling for half-baked CARS passages. Tackle 
                the real thing—and watch your score soar faster than you 
                thought possible.&rdquo;
              </blockquote>
              <div className="text-center">
                <div className="text-gray-900 font-medium">
                  The UPangea Team
                </div>
                <div className="text-blue-600 text-sm">
                  Redefining CARS for Pre-Meds Everywhere
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section
          id="features"
          ref={featuresRef}
          className="w-full py-12 md:py-24 bg-white"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">
              Core Beta Features
            </h2>
            <div className="grid gap-6 sm:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  icon: Brain,
                  title: "UPenguin AI Tutor",
                  description:
                    "On-demand explanations for every passage, logic breakdowns, and real-time strategy tips, day or night.",
                  color: "bg-blue-100 text-blue-600",
                },
                {
                  icon: CheckCircle,
                  title: "10,000+ CARS Questions",
                  description:
                    "Exhaustive, high-fidelity Q-bank. You’ll never run out of realistic material to sharpen your reading skills.",
                  color: "bg-green-100 text-green-600",
                },
                {
                  icon: Users,
                  title: "Pinpoint Analytics",
                  description:
                    "Track your timing, question-type performance, and historical progress. Eliminate guesswork on your weak spots.",
                  color: "bg-cyan-100 text-cyan-600",
                },
                {
                  icon: TrendingUp,
                  title: "4–6 Point Jumps",
                  description:
                    "Our focused approach and AI support result in measurable gains, often within a few months.",
                  color: "bg-teal-100 text-teal-600",
                },
                {
                  icon: Star,
                  title: "Fully AAMC-Style UI",
                  description:
                    "Practice in an interface built to match the real MCAT’s layout, features, and complexities, so test day feels second-nature.",
                  color: "bg-emerald-100 text-emerald-600",
                },
                {
                  icon: BarChart,
                  title: "Advanced Reporting",
                  description:
                    "From passage-level analysis to overall trends, see precisely how—and where—you’re improving each week.",
                  color: "bg-sky-100 text-sky-600",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`flex flex-col items-center text-center p-6 rounded-lg shadow-sm transition-all duration-300 hover:shadow-md hover:-translate-y-1 cursor-pointer ${feature.color}`}
                >
                  <feature.icon className="h-12 w-12 mb-4" />
                  <h3 className="text-xl font-bold mb-2 text-gray-900">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CARS-Only Score Calculator */}
        <section
          id="score-calculator"
          className="w-full py-12 md:py-24 bg-white"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">
              Estimate Your CARS Leap
            </h2>
            <div className="max-w-3xl mx-auto bg-gradient-to-br from-blue-100 to-green-100 p-4 sm:p-8 rounded-xl shadow-lg">
              <p className="text-center text-gray-600 mb-6">
                Slide to your current CARS score and see how high you 
                could climb after a few months of consistent AI-backed practice.
              </p>
              <div className="space-y-2 max-w-md mx-auto mb-8">
                <Label htmlFor="carsSlider" className="text-gray-700 font-bold">
                  Current CARS Score
                </Label>
                <Slider
                  id="carsSlider"
                  min={118}
                  max={132}
                  step={1}
                  value={[carsScore]}
                  onValueChange={(value) => setCarsScore(value[0])}
                  className="w-full"
                />
                <p className="text-center font-bold text-blue-600 text-lg">
                  {carsScore}
                </p>
              </div>
              <div className="text-center space-y-4">
                <div>
                  <h3 className="text-2xl font-bold text-blue-600">
                    Potential CARS Score
                  </h3>
                  <p className="text-3xl text-green-700 font-extrabold my-2">
                    {calculatePotentialImprovement()}
                  </p>
                  <p className="text-gray-600">
                    Many beta users report ~6 point gains with dedicated effort.
                  </p>
                </div>
                <Button
                  className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
                  onClick={handleSignUp}
                >
                  Start Your Beta Practice
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Future Pricing Section */}
        <section
          id="pricing"
          ref={pricingRef}
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-100 to-green-100"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight mb-8 text-center text-gray-900">
              Future Pricing (After Beta)
            </h2>
            <p className="text-center text-lg max-w-2xl mx-auto text-gray-700 mb-12">
              Our free beta won't last forever. Once we launch, you'll choose 
              from deposit-based plans with full refunds for top 10% CARS scores. 
              Lock in exclusive perks now—no cost, no catch—before these spots 
              close.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
              {/* 1 Month */}
              <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col border-2 border-transparent hover:border-blue-600 transition-all">
                <h3 className="text-xl font-bold text-center mb-2">
                  1 Month (Future)
                </h3>
                <p className="text-sm text-center text-gray-500 mb-4">
                  Includes AI tutor & CARS Q-bank
                </p>
                <div className="flex justify-center items-baseline mb-4">
                  <span className="text-3xl font-extrabold">$120</span>
                  <span className="text-sm text-gray-500 ml-1">deposit</span>
                </div>
                <ul className="space-y-3 mb-6 text-sm text-gray-700">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Full 10K+ CARS questions
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    AI Tutor (UPenguin)
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Personalized insights & analytics
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Full refund if you reach top 10% in CARS
                  </li>
                </ul>
                <Button
                  variant="outline"
                  className="w-full text-blue-600 border-blue-600 hover:bg-blue-50 mt-auto cursor-not-allowed"
                >
                  Coming Soon
                </Button>
              </div>

              {/* 3 Months */}
              <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col border-2 border-transparent hover:border-blue-600 transition-all">
                <h3 className="text-xl font-bold text-center mb-2">
                  3 Months (Future)
                </h3>
                <p className="text-sm text-center text-gray-500 mb-4">
                  Balanced, consistent practice
                </p>
                <div className="flex justify-center items-baseline mb-4">
                  <span className="text-3xl font-extrabold">$160</span>
                  <span className="text-sm text-gray-500 ml-1">deposit</span>
                </div>
                <ul className="space-y-3 mb-6 text-sm text-gray-700">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Full 10K+ CARS questions
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    UPenguin (AI + voice mode)
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Rich analytics
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Deposit refunded for top 10% in CARS
                  </li>
                </ul>
                <Button
                  variant="outline"
                  className="w-full text-blue-600 border-blue-600 hover:bg-blue-50 mt-auto cursor-not-allowed"
                >
                  Coming Soon
                </Button>
              </div>

              {/* 6 Months */}
              <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col border-2 border-transparent hover:border-blue-600 transition-all">
                <h3 className="text-xl font-bold text-center mb-2">
                  6 Months (Future)
                </h3>
                <p className="text-sm text-center text-gray-500 mb-4">
                  Build mastery at a steady pace
                </p>
                <div className="flex justify-center items-baseline mb-4">
                  <span className="text-3xl font-extrabold">$200</span>
                  <span className="text-sm text-gray-500 ml-1">deposit</span>
                </div>
                <ul className="space-y-3 mb-6 text-sm text-gray-700">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    10K+ CARS questions
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    UPenguin AI + voice
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Personalized insights
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Full refund for top 10% in CARS
                  </li>
                </ul>
                <Button
                  variant="outline"
                  className="w-full text-blue-600 border-blue-600 hover:bg-blue-50 mt-auto cursor-not-allowed"
                >
                  Coming Soon
                </Button>
              </div>
            </div>

            <p className="text-center mt-8 text-gray-700 text-sm">
              Secure your free beta access now. After we hit our user cap, any 
              new sign-ups might have to wait until official launch.
            </p>
          </div>
        </section>

        {/* Testimonials */}
        <section
          id="testimonials"
          className="w-full py-12 md:py-24 lg:py-32 bg-white"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-8 text-center text-gray-900">
              Beta Success Stories
            </h2>
            <div className="relative bg-gradient-to-br from-blue-100 to-green-100 p-8 rounded-xl shadow-lg max-w-3xl mx-auto">
              <div className="flex flex-col items-center text-center space-y-4">
                <p className="text-xl italic text-gray-700">
                  &quot;{testimonials[currentTestimonial].quote}&quot;
                </p>
                <div>
                  <p className="font-semibold text-gray-900">
                    {testimonials[currentTestimonial].name}
                  </p>
                  <p className="text-blue-600 font-bold">
                    {testimonials[currentTestimonial].score}
                  </p>
                </div>
              </div>
              <div className="flex justify-between mt-8">
                <Button
                  variant="outline"
                  onClick={() =>
                    setCurrentTestimonial((prev) =>
                      prev === 0 ? testimonials.length - 1 : prev - 1
                    )
                  }
                  className="text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Previous
                </Button>
                <Button
                  variant="outline"
                  onClick={() =>
                    setCurrentTestimonial((prev) =>
                      prev === testimonials.length - 1 ? 0 : prev + 1
                    )
                  }
                  className="text-blue-600 border-blue-600 hover:bg-blue-50"
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Stats */}
        <section
          id="stats"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-600 to-green-600"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-12 text-center text-white">
              UPangea by the Numbers
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 text-center">
              {[
                { value: "1,000+", label: "Beta Users" },
                { value: "10,000+", label: "CARS Questions" },
                { value: "6+", label: "Avg. Point Increase" },
                { value: "2X", label: "Faster Learning (Harvard)" },
              ].map((stat, index) => (
                <div
                  key={index}
                  className="space-y-2 bg-white/10 p-6 rounded-lg backdrop-blur-sm transition-all duration-300 hover:bg-white/20 hover:scale-105"
                >
                  <h3 className="text-4xl font-bold text-white">
                    {stat.value}
                  </h3>
                  <p className="text-blue-100">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section
          id="faq"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-b from-blue-50 to-green-50"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter md:text-4xl mb-8 text-center text-gray-900">
              Frequently Asked Questions
            </h2>
            <div className="max-w-3xl mx-auto space-y-4">
              {faqItems.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300"
                >
                  <button
                    className="flex justify-between items-center w-full p-4 text-left"
                    onClick={() => setOpenFAQ(openFAQ === index ? null : index)}
                  >
                    <span className="font-semibold text-gray-900">
                      {item.question}
                    </span>
                    {openFAQ === index ? (
                      <ChevronUp className="h-5 w-5 text-blue-600" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-blue-600" />
                    )}
                  </button>
                  <div
                    className={`p-4 bg-blue-50 transition-all duration-300 ${
                      openFAQ === index
                        ? "max-h-40 opacity-100"
                        : "max-h-0 opacity-0"
                    } overflow-hidden`}
                  >
                    <p className="text-gray-600">{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Signup Call-to-Action */}
        <section
          id="signup"
          className="w-full py-12 md:py-24 lg:py-32 bg-white"
        >
          <div className="container px-4 md:px-6 mx-auto">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-gray-900">
                  Ready to Experience Authentic CARS?
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-600 md:text-xl lg:text-base xl:text-xl">
                  Snap up one of our limited free beta seats, and discover 
                  how real AAMC-like passages plus an AI tutor can revolutionize 
                  your score. If we’re full, you may have to wait—so don’t miss out.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <Button
                  onClick={handleSignUp}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
                >
                  Sign Up Free (Beta)
                </Button>
                <p className="text-xs text-gray-600">
                  By signing up, you agree to our{" "}
                  <Link
                    to="/terms"
                    className="underline underline-offset-2 hover:text-blue-600"
                  >
                    Terms &amp; Conditions
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="w-full py-6 bg-gray-900 text-gray-300">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-semibold mb-2">Company</h3>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/contact"
                    className="hover:text-white transition-colors"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold mb-2">Legal</h3>
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/privacy"
                    className="hover:text-white transition-colors"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms"
                    className="hover:text-white transition-colors"
                  >
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            <p className="text-sm">
              © 2025 UPangea Inc. All rights reserved.
            </p>
            <div className="flex space-x-4 mt-4 sm:mt-0">
              <Link
                to="#"
                className="text-gray-300 hover:text-white transition-colors"
              >
                <span className="sr-only">Facebook</span>
              </Link>
              <Link
                to="#"
                className="text-gray-300 hover:text-white transition-colors"
              >
                <span className="sr-only">Instagram</span>
              </Link>
              <Link
                to="#"
                className="text-gray-300 hover:text-white transition-colors"
              >
                <span className="sr-only">Twitter</span>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
