import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../components/ui/card";
import {
  BarChart2,
  BookOpen,
  CheckCircle,
  Clock,
  Flag,
  CircleX,
  Brain,
  Search,
  MessageCircle,
  FileText,
} from "lucide-react";
import { supabase } from "../supabaseClient";
import { SessionContext } from "../index";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ScrollArea } from "../components/ui/scroll-area";
import { Input } from "../components/ui/input";

// Optional helper to format roman numeral questions
const formatRomanNumeralQuestion = (text) => {
  const lines = text.split("\n");
  if (lines.length >= 5) {
    const hasRomanNumerals =
      lines[2]?.trim().startsWith("I.") &&
      lines[3]?.trim().startsWith("II.") &&
      lines[4]?.trim().startsWith("III.");
    if (hasRomanNumerals) {
      return [
        lines[0],
        lines[1],
        `\u2003\u2003${lines[2]}`,
        `\u2003\u2003${lines[3]}`,
        `\u2003\u2003${lines[4]}`,
        ...lines.slice(5),
      ].join("\n");
    }
  }
  return text;
};

// Correct component name
export default function TestSummary({ isReviewMode: propIsReviewMode = false }) {
  const { testId } = useParams();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  const [testData, setTestData] = useState(null);
  const [passageAnalysis, setPassageAnalysis] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [filters, setFilters] = useState({
    questionType: "all",
    difficulty: "all",
    confidence: "all",
    result: "all",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!session) {
      navigate("/");
      return;
    }

    const fetchTestData = async () => {
      try {
        // 1. Fetch the main test record
        const { data: test, error: testError } = await supabase
          .from("tests_newest")
          .select("*, ai_summary") // ai_summary is actually no longer needed
          .eq("id", testId)
          .single();

        if (testError) throw testError;

        // 2. Fetch the passages
        const { data: passages, error: passagesError } = await supabase
          .from("new_passages")
          .select(
            `
              *,
              new_questions (
                id,
                text,
                correct_answer
              )
            `,
          )
          .in("id", test.passage_ids);

        if (passagesError) throw passagesError;

        // 3. Fetch the test answers
        const { data: answers, error: answersError } = await supabase
          .from("test_answers_newest")
          .select("*")
          .eq("test_id", testId);

        if (answersError) throw answersError;

        // Enrich the test object
        const enrichedTest = {
          ...test,
          passages,
          answers,
          totalPassages: passages.length,
          totalQuestions: passages.reduce(
            (sum, p) => sum + p.new_questions.length,
            0,
          ),
          correctAnswers: answers.filter((a) => a.is_correct).length,
          flaggedQuestions: answers.filter((a) => a.flagged).length,
          confidenceData: {
            High: answers.filter((a) => a.confidence_rating === "High").length,
            Medium: answers.filter((a) => a.confidence_rating === "Medium")
              .length,
            Low: answers.filter((a) => a.confidence_rating === "Low").length,
          },
        };

        setTestData(enrichedTest);

        // 4. Fetch detailed passage data
        if (test.passage_ids && test.passage_ids.length > 0) {
          const { data: passagesData, error: passagesError2 } = await supabase
            .from("new_passages")
            .select(
              `
                id,
                passage_topic,
                passage_discipline,
                passage_reading_difficulty,
                new_questions (
                  id,
                  text
                )
              `,
            )
            .in("id", test.passage_ids);

          if (passagesError2) throw passagesError2;

          // 5. Reuse the answers from above for final combination
          const passageDetails = passagesData.map((passage) => {
            const passageAnswers = answers.filter(
              (ans) => ans.passage_id === passage.id,
            );
            const correctAnswers = passageAnswers.filter((ans) => ans.is_correct)
              .length;

            return {
              ...passage,
              accuracy: (correctAnswers / passage.new_questions.length) * 100,
              totalQuestions: passage.new_questions.length,
              correctAnswers,
            };
          });

          setPassageAnalysis(passageDetails);
        }
      } catch (error) {
        console.error("Error fetching test data:", error);
      }
    };

    fetchTestData();
  }, [testId, session, navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (!testData?.passages) return;

        // 6. Fetch the more detailed question info
        const { data: questionsData, error: questionsError } = await supabase
          .from("new_questions")
          .select(
            `
              id,
              text,
              question_type,
              question_difficulty,
              correct_answer
            `,
          )
          .in(
            "id",
            testData.passages.flatMap((p) => p.new_questions.map((q) => q.id)),
          );

        if (questionsError) throw questionsError;

        // Combine each question with the user's answer
        const enrichedQuestions = questionsData.map((q) => {
          const match = testData.answers.find((a) => a.question_id === q.id);
          return {
            ...q,
            confidence: match?.confidence_rating || "Not Rated",
            isCorrect: match?.is_correct || false,
            selectedAnswer: match?.selected_answer,
          };
        });

        setQuestions(enrichedQuestions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [testData]);

  // Renders each passage's analysis card
  const renderPassageAnalysis = () => {
    if (!passageAnalysis.length) return null;

    return (
      <Card className="mt-8">
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-xl">
            <BookOpen className="h-5 w-5" />
            Passage Analysis
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {passageAnalysis.map((passage, index) => (
              <Card
                key={passage.id}
                className="overflow-hidden border shadow-sm"
              >
                <div className="relative">
                  {/* Difficulty badge in top-right */}
                  <div className="absolute top-3 right-3">
                    <span
                      className={cn(
                        "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                        passage.passage_reading_difficulty === "Challenging"
                          ? "bg-red-100 text-red-800"
                          : "bg-yellow-100 text-yellow-800",
                      )}
                    >
                      {passage.passage_reading_difficulty}
                    </span>
                  </div>

                  {/* Card Header */}
                  <CardHeader>
                    <CardTitle className="text-lg">
                      Passage-{index + 1}: {passage.passage_topic}
                    </CardTitle>
                    <CardDescription>{passage.passage_discipline}</CardDescription>
                  </CardHeader>
                </div>

                <CardContent className="space-y-4">
                  {/* Reading Time */}
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <Clock className="h-4 w-4" />
                    <span>
                      Reading Time:{" "}
                      {testData.passage_elapsed_time?.[index]
                        ? Math.round(testData.passage_elapsed_time[index] / 60)
                        : "N/A"}{" "}
                      mins
                    </span>
                  </div>

                  {/* Accuracy */}
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="font-medium">Accuracy</span>
                      <span
                        className={cn(
                          "font-bold",
                          passage.accuracy >= 80
                            ? "text-green-600"
                            : passage.accuracy >= 60
                            ? "text-yellow-600"
                            : "text-red-600",
                        )}
                      >
                        {Math.round(passage.accuracy)}%
                      </span>
                    </div>
                    <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div
                        className={cn(
                          "h-full rounded-full transition-all duration-500",
                          passage.accuracy >= 80
                            ? "bg-gradient-to-r from-green-500 to-green-400"
                            : passage.accuracy >= 60
                            ? "bg-gradient-to-r from-yellow-500 to-yellow-400"
                            : "bg-gradient-to-r from-red-500 to-red-400",
                        )}
                        style={{ width: `${passage.accuracy}%` }}
                      />
                    </div>
                  </div>

                  {/* Questions Summary */}
                  <div className="flex justify-between items-center pt-2 border-t">
                    <span className="text-sm text-gray-600">Questions:</span>
                    <span className="font-medium">
                      {passage.correctAnswers} / {passage.totalQuestions} correct
                    </span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  };

  // Filters the question list
  const filteredQuestions = questions.filter((question) => {
    const matchesType =
      filters.questionType === "all" || question.question_type === filters.questionType;
    const matchesDifficulty =
      filters.difficulty === "all" ||
      question.question_difficulty === filters.difficulty;
    const matchesConfidence =
      filters.confidence === "all" || question.confidence === filters.confidence;
    const matchesResult =
      filters.result === "all" ||
      (filters.result === "correct" && question.isCorrect) ||
      (filters.result === "incorrect" && !question.isCorrect);
    const matchesSearch =
      searchQuery === "" ||
      question.text.toLowerCase().includes(searchQuery.toLowerCase());

    return (
      matchesType &&
      matchesDifficulty &&
      matchesConfidence &&
      matchesResult &&
      matchesSearch
    );
  });

  // Renders the question analysis table
  const renderQuestionAnalysis = () => {
    const uniqueTypes = [...new Set(questions.map((q) => q.question_type))];
    const uniqueDifficulties = [...new Set(questions.map((q) => q.question_difficulty))];

    return (
      <Card className="mt-8">
        <CardHeader>
          <CardTitle className="flex items-center gap-2 text-xl">
            <FileText className="h-5 w-5" />
            Question Analysis
          </CardTitle>
        </CardHeader>
        <CardContent>
          {/* Filters */}
          <div className="mb-6 space-y-4">
            <div className="flex items-center gap-4">
              {/* Search */}
              <div className="flex items-center gap-2">
                <Search className="h-4 w-4 text-gray-500" />
                <Input
                  placeholder="Search questions..."
                  className="w-[200px]"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              {/* Type / Difficulty / Confidence / Result */}
              <div className="flex items-center gap-4">
                <Select
                  value={filters.questionType}
                  onValueChange={(value) =>
                    setFilters((prev) => ({ ...prev, questionType: value }))
                  }
                >
                  <SelectTrigger className="w-[160px]">
                    <SelectValue placeholder="Question Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Types</SelectItem>
                    {uniqueTypes.map((type) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select
                  value={filters.difficulty}
                  onValueChange={(value) =>
                    setFilters((prev) => ({ ...prev, difficulty: value }))
                  }
                >
                  <SelectTrigger className="w-[160px]">
                    <SelectValue placeholder="Difficulty" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Difficulties</SelectItem>
                    {uniqueDifficulties.map((diff) => (
                      <SelectItem key={diff} value={diff}>
                        {diff}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select
                  value={filters.confidence}
                  onValueChange={(value) =>
                    setFilters((prev) => ({ ...prev, confidence: value }))
                  }
                >
                  <SelectTrigger className="w-[160px]">
                    <SelectValue placeholder="Confidence" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Confidence</SelectItem>
                    <SelectItem value="High">High</SelectItem>
                    <SelectItem value="Medium">Medium</SelectItem>
                    <SelectItem value="Low">Low</SelectItem>
                  </SelectContent>
                </Select>

                <Select
                  value={filters.result}
                  onValueChange={(value) =>
                    setFilters((prev) => ({ ...prev, result: value }))
                  }
                >
                  <SelectTrigger className="w-[160px]">
                    <SelectValue placeholder="Result" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Results</SelectItem>
                    <SelectItem value="correct">Correct</SelectItem>
                    <SelectItem value="incorrect">Incorrect</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>

          {/* Questions Table */}
          <ScrollArea className="h-[400px] rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">Question #</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Difficulty</TableHead>
                  <TableHead>Confidence</TableHead>
                  <TableHead>Result</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredQuestions.map((question, idx) => (
                  <TableRow key={question.id}>
                    <TableCell className="font-medium text-center">
                      {idx + 1}
                    </TableCell>
                    <TableCell>{question.question_type}</TableCell>
                    <TableCell>
                      <span
                        className={cn(
                          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                          question.question_difficulty === "Hard"
                            ? "bg-red-100 text-red-800"
                            : question.question_difficulty === "Medium"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-green-100 text-green-800",
                        )}
                      >
                        {question.question_difficulty}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className={cn(
                          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium",
                          question.confidence === "High"
                            ? "bg-green-100 text-green-800"
                            : question.confidence === "Medium"
                            ? "bg-yellow-100 text-yellow-800"
                            : question.confidence === "Low"
                            ? "bg-red-100 text-red-800"
                            : "bg-gray-100 text-gray-800", // fallback if "Not Rated"
                        )}
                      >
                        {question.confidence}
                      </span>
                    </TableCell>
                    <TableCell>
                      {question.isCorrect ? (
                        <span className="inline-flex items-center text-green-600">
                          <CheckCircle className="w-4 h-4 mr-1" />
                          Correct
                        </span>
                      ) : (
                        <span className="inline-flex items-center text-red-600">
                          <CircleX className="w-4 h-4 mr-1" />
                          Incorrect
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollArea>
        </CardContent>
      </Card>
    );
  }; // <-- Make sure we close the function cleanly here, with NO extra brace after

  // Simple MCAT Score approximation
  const calculateMCATScore = (percentage) => {
    if (percentage >= 98) return 132;
    if (percentage >= 96) return 131;
    if (percentage >= 92) return 130;
    if (percentage >= 89) return 129;
    if (percentage >= 85) return 128;
    if (percentage >= 81) return 127;
    if (percentage >= 77) return 126;
    if (percentage >= 74) return 125;
    if (percentage >= 70) return 124;
    if (percentage >= 66) return 123;
    if (percentage >= 62) return 122;
    if (percentage >= 58) return 121;
    if (percentage >= 55) return 120;
    if (percentage >= 51) return 119;
    if (percentage >= 47) return 118;
    if (percentage > 0) return 117;
    return null;
  };

  if (!testData) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Enhanced Test Overview Section */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Score Card */}
          <Card className="lg:col-span-2 border-0 shadow-lg bg-white/90 backdrop-blur-sm overflow-hidden">
            <div className="bg-gradient-to-r from-blue-600 to-green-600 p-6">
              <h2 className="text-xl font-medium text-white/90">
                Test Performance Overview
              </h2>
              <p className="text-sm text-white/80">
                Completed on{" "}
                {new Date(testData.test_date).toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
            <CardContent className="p-6">
              <div className="flex items-center justify-between mb-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Total Score</p>
                  <p className="text-5xl font-bold bg-gradient-to-r from-blue-600 to-green-600 bg-clip-text text-transparent">
                    {testData.score}%
                  </p>
                </div>
                <div className="h-24 w-24 relative">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <svg className="transform -rotate-90 w-24 h-24">
                      <circle
                        className="text-gray-200"
                        strokeWidth="8"
                        stroke="currentColor"
                        fill="transparent"
                        r="42"
                        cx="48"
                        cy="48"
                      />
                      <circle
                        className={cn(
                          "transition-all duration-500",
                          testData.score >= 94
                            ? "text-green-600"
                            : testData.score >= 83
                            ? "text-blue-600"
                            : testData.score >= 72
                            ? "text-yellow-600"
                            : "text-red-600",
                        )}
                        strokeWidth="8"
                        strokeDasharray={264}
                        strokeDashoffset={264 - (264 * testData.score) / 100}
                        strokeLinecap="round"
                        stroke="currentColor"
                        fill="transparent"
                        r="42"
                        cx="48"
                        cy="48"
                      />
                    </svg>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="text-center">
                        <span
                          className={cn(
                            "text-2xl font-bold",
                            testData.score >= 94
                              ? "text-green-600"
                              : testData.score >= 83
                              ? "text-blue-600"
                              : testData.score >= 72
                              ? "text-yellow-600"
                              : "text-red-600",
                          )}
                        >
                          {calculateMCATScore(testData.score)}
                        </span>
                        <span className="block text-xs text-gray-500">
                          Score
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center gap-3">
                    <Brain className="h-5 w-5 text-blue-600" />
                    <div>
                      <p className="text-sm text-gray-500">Correct Answers</p>
                      <p className="text-xl font-bold text-gray-900">
                        {testData.correctAnswers} / {testData.totalQuestions}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center gap-3">
                    <Clock className="h-5 w-5 text-green-600" />
                    <div>
                      <p className="text-sm text-gray-500">Time Spent</p>
                      <p className="text-xl font-bold text-gray-900">
                        {Math.floor(testData.elapsed_time / 60)} mins
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Confidence Distribution Card */}
          <Card className="border-0 shadow-lg bg-white/90 backdrop-blur-sm">
            <CardHeader>
              <CardTitle className="text-lg font-medium text-gray-900">
                Confidence Distribution
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {Object.entries(testData.confidenceData).map(
                  ([level, count]) => (
                    <div key={level} className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">{level}</span>
                        <span className="font-medium">
                          {count} (
                          {Math.round((count / testData.totalQuestions) * 100)}
                          %)
                        </span>
                      </div>
                      <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div
                          className={cn(
                            "h-full rounded-full transition-all duration-500",
                            level === "High"
                              ? "bg-green-500"
                              : level === "Medium"
                              ? "bg-yellow-500"
                              : "bg-red-500",
                          )}
                          style={{
                            width: `${(count / testData.totalQuestions) * 100}%`,
                          }}
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>

              <div className="mt-6 pt-6 border-t border-gray-200">
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center gap-2">
                    <Flag className="h-4 w-4 text-yellow-500" />
                    <span>Flagged Questions</span>
                  </div>
                  <span className="font-medium text-gray-900">
                    {testData.flaggedQuestions}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Passage Analysis Section */}
        {renderPassageAnalysis()}

        {/* Question Analysis Section */}
        {renderQuestionAnalysis()}

        {/* Next Steps Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
          <Button
            onClick={() => navigate(`/test/${testId}/review`)}
            className="bg-blue-600 hover:bg-blue-700 text-white h-auto py-6 flex flex-col items-center gap-2"
          >
            <BookOpen className="h-6 w-6" />
            <span className="text-lg font-semibold">Review Test</span>
            <span className="text-sm opacity-90">
              Analyze your answers in detail
            </span>
          </Button>

          <Button
            onClick={() => navigate("/dashboard")}
            variant="outline"
            className="bg-white hover:bg-gray-50 h-auto py-6 flex flex-col items-center gap-2"
          >
            <BarChart2 className="h-6 w-6" />
            <span className="text-lg font-semibold">Back to Dashboard</span>
            <span className="text-sm opacity-90">Continue your MCAT prep</span>
          </Button>

          <Button
            onClick={() =>
              window.open("https://calendly.com/nickv-testkey/30min", "_blank")
            }
            className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white h-auto py-6 flex flex-col items-center gap-2 relative overflow-hidden group"
          >
            <div className="absolute inset-0 bg-grid-white/10 bg-grid-8"></div>
            <div className="relative z-10 flex flex-col items-center">
              <MessageCircle className="h-6 w-6" />
              <span className="text-lg font-semibold">
                Free Tutoring Session
              </span>
              <span className="text-sm opacity-90">
                Book a 1:1 session with an expert
              </span>
              <div className="absolute -top-1 -right-1 bg-yellow-500 text-xs text-black px-2 py-1 rotate-12 transform scale-90 group-hover:scale-100 transition-transform">
                FREE
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
