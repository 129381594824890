import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import logoImage from "../assets/images/5.svg";

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
          <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
            UPangea
          </span>
        </Link>
        <div className="ml-auto">
          <Button
            asChild
            variant="outline"
            className="text-blue-600 border-blue-600 hover:bg-blue-50"
          >
            <Link to="/">Back to Home</Link>
          </Button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 pt-32 pb-16 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-gray-900">
          Terms of Service
        </h1>
        <div className="space-y-8 text-gray-700">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Agreement to Terms
            </h2>
            <p className="mb-4">
              By accessing and using UPangea's services, you agree to be bound
              by these Terms of Service. If you disagree with any part of these
              terms, you may not access our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. Description of Service
            </h2>
            <p className="mb-4">
              UPangea provides an online MCAT preparation platform that includes
              practice questions, performance tracking, and educational content.
              We reserve the right to modify, suspend, or discontinue any aspect
              of our services at any time.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. User Accounts
            </h2>
            <ul className="list-disc pl-6 mb-4">
              <li>
                You must provide accurate and complete information when creating
                an account
              </li>
              <li>
                You are responsible for maintaining the security of your account
                credentials
              </li>
              <li>
                You must notify us immediately of any unauthorized access to
                your account
              </li>
              <li>
                We reserve the right to terminate accounts that violate our
                terms
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. Subscription and Payments
            </h2>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Subscription fees are billed in advance on a monthly or annual
                basis
              </li>
              <li>Refunds are provided in accordance with our refund policy</li>
              <li>
                We reserve the right to modify pricing with reasonable notice
              </li>
              <li>Failed payments may result in service interruption</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Intellectual Property
            </h2>
            <p className="mb-4">
              All content, features, and functionality of UPangea's services are
              owned by UPangea and are protected by international copyright,
              trademark, and other intellectual property laws.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. User Conduct
            </h2>
            <p className="mb-4">Users agree not to:</p>
            <ul className="list-disc pl-6">
              <li>Share account credentials with others</li>
              <li>Copy or distribute our content without permission</li>
              <li>Use our services for unauthorized commercial purposes</li>
              <li>Attempt to bypass any security measures</li>
              <li>Engage in any activity that disrupts our services</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. Limitation of Liability
            </h2>
            <p className="mb-4">
              UPangea shall not be liable for any indirect, incidental, special,
              consequential, or punitive damages resulting from your use or
              inability to use our services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Changes to Terms
            </h2>
            <p className="mb-4">
              We reserve the right to modify these terms at any time. We will
              notify users of any material changes via email or through our
              platform.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              9. Contact Information
            </h2>
            <p>
              For questions about these Terms of Service, please contact us at:{" "}
              <a
                href="mailto:support@upangea.com"
                className="text-blue-600 hover:underline"
              >
                support@upangea.com
              </a>
            </p>
          </section>

          <section>
            <p className="mt-8 font-medium">Last Updated: January 1, 2024</p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-6">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-sm">© 2024 UPangea Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
