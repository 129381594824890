import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Button } from "../components/ui/button";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { ScrollArea } from "../components/ui/scroll-area";
import { Switch } from "../components/ui/switch";
import {
  Clock,
  Flag,
  ChevronLeft,
  ChevronRight,
  Menu,
  MessageSquare,
  Highlighter,
  Scissors,
  Play,
  Eraser,
  CheckCircle,
  XCircle,
  AlertCircle,
  MessageCircle,
  Icon,
} from "lucide-react";
import { penguin } from "@lucide/lab";
import { useHotkeys } from "react-hotkeys-hook";
import { SessionContext } from "../index";
import { GoogleGenerativeAI } from "@google/generative-ai";
import { cn } from "../lib/utils";

// Helper function to detect and format roman numeral questions
const formatRomanNumeralQuestion = (text) => {
  const lines = text.split("\n");
  if (lines.length >= 5) {
    // Check if lines 3, 4, and 5 start with I, II, and III respectively
    const hasRomanNumerals =
      lines[2]?.trim().startsWith("I.") &&
      lines[3]?.trim().startsWith("II.") &&
      lines[4]?.trim().startsWith("III.");

    if (hasRomanNumerals) {
      // Format the question with proper indentation
      return [
        lines[0], // Question
        lines[1], // Empty line
        `\u2003\u2003${lines[2]}`, // Indented I.
        `\u2003\u2003${lines[3]}`, // Indented II.
        `\u2003\u2003${lines[4]}`, // Indented III.
        ...lines.slice(5),
      ].join("\n");
    }
  }
  return text;
};

export default function TestInterface({
  isReviewMode: propIsReviewMode = false,
}) {
  const { testId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const imagePrefixUrl =
    "https://lzgkrezflvwezmjakdyv.supabase.co/storage/v1/object/public/upangea_images/";

  // State variables
  const [passages, setPassages] = useState([]);
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showSolution, setShowSolution] = useState(false);
  const [markups, setMarkups] = useState({});
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);
  const [showNavigation, setShowNavigation] = useState(false);
  const [showEndTestConfirmation, setShowEndTestConfirmation] = useState(false);
  const [showTestSummary, setShowTestSummary] = useState(false);
  const [confidenceRatings, setConfidenceRatings] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [lockedQuestions, setLockedQuestions] = useState({});
  const [isCorrectAnswers, setIsCorrectAnswers] = useState({});
  const [selectedBigAnswer, setSelectedBigAnswer] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [chatContext, setChatContext] = useState("");
  const [userMemory, setUserMemory] = useState([]);
  const [isProcessingMemory, setIsProcessingMemory] = useState(false);
  const [isEndingTest, setIsEndingTest] = useState(false);

  const currentPassage = passages[currentPassageIndex] || null;
  const currentQuestion =
    currentPassage?.new_questions[currentQuestionIndex] || null;
  const contentRef = useRef(null);

  // Add these new state variables after other state declarations
  const [passageStartTime, setPassageStartTime] = useState(Date.now());
  const [passageElapsedTimes, setPassageElapsedTimes] = useState({});

  // Determine if we're in review mode based on both prop and URL
  const isReviewMode =
    propIsReviewMode || location.pathname.includes("/review");

  const formatContextForAI = useCallback(() => {
    let context = "";
    if (!currentPassage || !currentQuestion) {
      return context;
    }

    // Helper function to get markups for a specific element
    const getMarkupsForElement = (elementId) => {
      return (markups[elementId] || []).sort(
        (a, b) => a.range.start - b.range.start,
      );
    };

    // Helper function to format text with markup annotations
    const formatTextWithMarkups = (text, elementId) => {
      const elementMarkups = getMarkupsForElement(elementId);
      if (!elementMarkups.length) return text;

      let result = [];
      let lastIndex = 0;

      elementMarkups.forEach((markup) => {
        // Add text before markup
        if (markup.range.start > lastIndex) {
          result.push(text.slice(lastIndex, markup.range.start));
        }

        // Add marked up text with annotation
        const markedText = text.slice(markup.range.start, markup.range.end);
        result.push(`[${markup.type.toUpperCase()}: ${markedText}]`);

        lastIndex = markup.range.end;
      });

      // Add remaining text
      if (lastIndex < text.length) {
        result.push(text.slice(lastIndex));
      }

      return result.join("");
    };

    // Format passage with markups
    const passageWithMarkups = formatTextWithMarkups(
      currentPassage.passage_text,
      `passage-${currentPassage.id}`,
    );
    context += `PASSAGE:\n${passageWithMarkups}\n\n`;

    // Format question with markups
    const questionWithMarkups = formatTextWithMarkups(
      currentQuestion.text,
      `question-${currentQuestion.id}`,
    );
    context += `QUESTION:\n${questionWithMarkups}\n\n`;

    // Format answer choices with markups
    context += `ANSWER CHOICES:\n`;
    currentQuestion.new_options.forEach((choice, index) => {
      const choiceWithMarkups = formatTextWithMarkups(
        choice.text,
        `choice-${currentQuestion.id}-${index}`,
      );
      context += `${String.fromCharCode(65 + index)}. ${choiceWithMarkups}\n`;
    });

    if (showSolution || isReviewMode) {
      context += `\nCORRECT ANSWER: ${currentQuestion.correct_answer}\n`;
      const explanationWithMarkups = formatTextWithMarkups(
        currentQuestion.explanation,
        `question-explanation-${currentQuestion.id}`,
      );
      context += `EXPLANATION:\n${explanationWithMarkups}\n`;
    }

    // Add a note about markups if any exist
    const hasMarkups = Object.keys(markups).some(
      (key) => markups[key].length > 0,
    );
    if (hasMarkups) {
      context += `\nNOTE: Text enclosed in [HIGHLIGHT: ...] or [STRIKETHROUGH: ...] indicates user markups.`;
    }

    return context;
  }, [currentPassage, currentQuestion, showSolution, isReviewMode, markups]);

  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content:
        "Hi! I'm UPenguin, your AI tutor. I can help you understand the passage and questions. Feel free to ask me anything!",
      timestamp: Date.now(),
    },
  ]);
  const [messageHistory, setMessageHistory] = useState({});

  useEffect(() => {
    // Save messages whenever they change
    if (currentQuestion?.id) {
      setMessageHistory((prev) => ({
        ...prev,
        [currentQuestion.id]: messages,
      }));
    }
  }, [messages, currentQuestion?.id]);

  // Load previous messages when changing questions
  useEffect(() => {
    if (currentQuestion?.id && messageHistory[currentQuestion.id]) {
      setMessages(messageHistory[currentQuestion.id]);
    } else {
      // Reset to initial greeting for new questions
      setMessages([
        {
          role: "assistant",
          content:
            "Hi! I'm UPenguin, your AI tutor. I can help you understand the passage and questions. Feel free to ask me anything!",
          timestamp: Date.now(),
        },
      ]);
    }
  }, [currentQuestion?.id]);

  // Add useEffect to initialize timing when component mounts or passages change
  useEffect(() => {
    if (!isReviewMode && passages.length > 0) {
      setPassageStartTime(Date.now());
      setPassageElapsedTimes({});
    }
  }, [passages, isReviewMode]);

  // Add useEffect to handle timing updates when user ends test
  useEffect(() => {
    if (showEndTestConfirmation || showTestSummary) {
      updateCurrentPassageTime();
    }
  }, [showEndTestConfirmation, showTestSummary]);

  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_AI_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-2.0-flash-exp" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = input.trim();
    setInput("");

    // Add user message with timestamp
    const newUserMessage = {
      role: "user",
      content: userMessage,
      timestamp: Date.now(),
    };
    setMessages((prev) => [...prev, newUserMessage]);

    try {
      setIsTyping(true);

      // Include previous messages in the context
      const conversationHistory = messages
        .map(
          (msg) =>
            `${msg.role === "user" ? "User" : "Assistant"}: ${msg.content}`,
        )
        .join("\n");

      // Include memory context in the prompt
      const memoryContext =
        userMemory.length > 0
          ? `Previous observations about this student from previous tests:\n${userMemory.map((m) => `- ${m}`).join("\n")}\n\n`
          : "";

      const prompt = `You are a helpful AI tutor assisting with MCAT test preparation. 
      Use natural language without any formatting (no markdown should be used). Keep your well reasoned responses concise and to the point.
      ${memoryContext}
      Previous conversation during this test session:
      ${conversationHistory}
      
      Context:
      ${chatContext}
      
      User question: ${userMessage}
      
      Provide a helpful, accurate response while considering the student's history and learning patterns.`;

      const result = await model.generateContent(prompt);
      const response = result.response;
      const text = response.text();

      // Add AI response with timestamp
      const newAssistantMessage = {
        role: "assistant",
        content: text,
        timestamp: Date.now(),
      };
      setMessages((prev) => [...prev, newAssistantMessage]);
    } catch (error) {
      console.error("Error generating AI response:", error);
      setMessages((prev) => [
        ...prev,
        {
          role: "assistant",
          content: "Sorry, I encountered an error. Please try again.",
          timestamp: Date.now(),
        },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  useEffect(() => {
    setChatContext(formatContextForAI());
  }, [currentPassage, currentQuestion, showSolution, formatContextForAI]);

  // Helper Functions
  const getTotalQuestions = useCallback(() => {
    return passages.reduce(
      (total, passage) => total + (passage.new_questions?.length || 0),
      0,
    );
  }, [passages]);

  const getCurrentQuestionNumber = useCallback(() => {
    let questionNumber = currentQuestionIndex + 1;
    for (let i = 0; i < currentPassageIndex; i++) {
      questionNumber += passages[i].new_questions?.length || 0;
    }
    return questionNumber;
  }, [currentQuestionIndex, currentPassageIndex, passages]);

  const getQuestionRangeForPassage = useCallback(
    (passageIndex) => {
      let startingQuestion = 1;
      for (let i = 0; i < passageIndex; i++) {
        startingQuestion += passages[i].new_questions.length;
      }
      const endingQuestion =
        startingQuestion +
        (passages[passageIndex]?.new_questions?.length || 0) -
        1;
      return { startingQuestion, endingQuestion };
    },
    [passages],
  );

  const isLastQuestion = useCallback(() => {
    return (
      currentPassageIndex === passages.length - 1 &&
      currentQuestionIndex === (currentPassage?.new_questions.length || 0) - 1
    );
  }, [currentPassageIndex, currentQuestionIndex, passages, currentPassage]);

  const getActualQuestionNumber = useCallback(
    (passageIndex, questionIndex) => {
      let questionNumber = 1;
      for (let i = 0; i < passageIndex; i++) {
        questionNumber += passages[i].new_questions.length;
      }
      return questionNumber + questionIndex;
    },
    [passages],
  );

  // Helper function to get the selected range
  const getSelectedRange = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return null;

    const range = selection.getRangeAt(0);
    const startContainer = range.startContainer;
    const endContainer = range.endContainer;

    if (
      !(startContainer.parentElement instanceof HTMLElement) ||
      !(endContainer.parentElement instanceof HTMLElement)
    ) {
      return null;
    }

    const startElement = startContainer.parentElement.closest("[data-offset]");
    const endElement = endContainer.parentElement.closest("[data-offset]");

    if (!startElement || !endElement) return null;

    const elementId = startElement.getAttribute("data-element-id") || "";
    const startOffset =
      parseInt(startElement.getAttribute("data-offset") || "0", 10) +
      range.startOffset;
    const endOffset =
      parseInt(endElement.getAttribute("data-offset") || "0", 10) +
      range.endOffset;

    return {
      range: { start: startOffset, end: endOffset },
      elementId,
    };
  };

  // Function to handle applying/removing markups
  const handleMarkup = useCallback((type) => {
    const selectionInfo = getSelectedRange();
    if (selectionInfo) {
      const { range, elementId } = selectionInfo;
      setMarkups((prev) => {
        const existingMarkups = prev[elementId] || [];
        const updatedMarkups = existingMarkups.filter(
          (markup) =>
            !(
              markup.range.start === range.start &&
              markup.range.end === range.end &&
              markup.type === type
            ),
        );

        if (updatedMarkups.length === existingMarkups.length) {
          // If no existing markup of this type, add it
          updatedMarkups.push({ type, range });
        }

        return {
          ...prev,
          [elementId]: updatedMarkups,
        };
      });
    }
  }, []);

  // Hotkeys for Markup
  const handleHighlight = useCallback(() => {
    handleMarkup("highlight");
  }, [handleMarkup]);

  const handleStrikethrough = useCallback(() => {
    handleMarkup("strikethrough");
  }, [handleMarkup]);

  useHotkeys("alt+h", handleHighlight, { preventDefault: true });
  useHotkeys("alt+s", handleStrikethrough, { preventDefault: true });

  // First, define handleConfidenceRating near the top with other state handlers
  const handleConfidenceRating = useCallback(
    (rating) => {
      if (currentQuestion) {
        setConfidenceRatings((prev) => ({
          ...prev,
          [currentQuestion.id]: rating,
        }));
      }
    },
    [currentQuestion],
  );

  // Then define handleConfidenceHotkey after handleConfidenceRating
  const handleConfidenceHotkey = useCallback(
    (rating) => {
      const activeElement = document.activeElement;
      if (activeElement?.id === "chat-input") return;

      // Prevent default behavior and stop propagation
      if (currentQuestion && selectedAnswers[currentQuestion.id] !== undefined) {
        // Blur any focused element
        if (activeElement) {
          activeElement.blur();
        }
        handleConfidenceRating(rating);
      }
    },
    [currentQuestion, selectedAnswers, handleConfidenceRating],
  );

  // Add hotkey bindings after both handlers are defined
  useHotkeys("1", () => handleConfidenceHotkey("Low"), {
    preventDefault: true,
    enableOnFormTags: true, // Enable on form elements
    stopPropagation: true,
    priority: 1, // Add higher priority
  }, [handleConfidenceHotkey]);

  useHotkeys("2", () => handleConfidenceHotkey("Medium"), {
    preventDefault: true,
    enableOnFormTags: true,
    stopPropagation: true,
    priority: 1,
  }, [handleConfidenceHotkey]);

  useHotkeys("3", () => handleConfidenceHotkey("High"), {
    preventDefault: true,
    enableOnFormTags: true,
    stopPropagation: true,
    priority: 1,
  }, [handleConfidenceHotkey]);

  // Fetch Test Data
  const fetchTestData = async () => {
    if (!testId || !session) return;

    setIsLoading(true);

    try {
      const supabaseUserId = session.user.id;

      // Fetch the test data
      const { data: testData, error: testError } = await supabase
        .from("tests_newest")
        .select("*")
        .eq("id", testId)
        .eq("user_id", supabaseUserId)
        .single();

      if (testError) throw testError;

      // Now fetch the passages using the passage_ids
      const passageIds = testData.passage_ids;

      const { data: passagesData, error: passagesError } = await supabase
        .from("new_passages")
        .select(
          `
          *,
          new_questions (
            id,
            text,
            correct_answer,
            explanation,
            new_options (*)
          )
        `,
        )
        .in("id", passageIds);

      if (passagesError) throw passagesError;

      setPassages(passagesData);

      // If in review mode, fetch selected answers
      if (isReviewMode) {
        const { data: selectedAnswersData, error: selectedAnswersError } =
          await supabase
            .from("test_answers_newest")
            .select("*")
            .eq("test_id", testId)
            .order("question_id", { ascending: true });

        if (selectedAnswersError) throw selectedAnswersError;

        const selectedAnswersObj = {};
        const confidenceRatingsObj = {};
        const flaggedQuestionsArr = [];
        const markupsObj = {};
        const isCorrectObj = {};

        // Initialize all questions as undefined first
        passagesData.forEach((passage) => {
          passage.new_questions.forEach((question) => {
            selectedAnswersObj[question.id] = undefined;
            confidenceRatingsObj[question.id] = null;
            isCorrectObj[question.id] = false;
          });
        });

        // Then update only the answered questions
        selectedAnswersData.forEach((answer) => {
          if (!answer) return;

          // Store selected answers (only if there was an answer)
          if (answer.selected_answer) {
            selectedAnswersObj[answer.question_id] = labelToIndex(
              answer.selected_answer,
            );
          }

          // Store confidence ratings
          confidenceRatingsObj[answer.question_id] =
            answer.confidence_rating || null;

          // Store flagged questions
          if (answer.flagged) {
            flaggedQuestionsArr.push(answer.question_id);
          }

          // Store all markups, ensuring they're valid JSON
          try {
            if (answer.question_markups) {
              markupsObj[`question-${answer.question_id}`] = JSON.parse(
                answer.question_markups,
              );
            }

            if (answer.choice_markups) {
              const choiceMarkups = JSON.parse(answer.choice_markups);
              choiceMarkups.forEach((markup, index) => {
                markupsObj[`choice-${answer.question_id}-${index}`] = markup;
              });
            }

            if (answer.passage_markups) {
              markupsObj[`passage-${answer.passage_id}`] = JSON.parse(
                answer.passage_markups,
              );
            }
          } catch (e) {
            console.error("Error parsing markups:", e);
          }

          // Store correct/incorrect status
          isCorrectObj[answer.question_id] = answer.is_correct;
        });

        setSelectedAnswers(selectedAnswersObj);
        setConfidenceRatings(confidenceRatingsObj);
        setFlaggedQuestions(flaggedQuestionsArr);
        setMarkups(markupsObj);
        setIsCorrectAnswers(isCorrectObj);
        setSelectedBigAnswer(selectedAnswersData);

        // Lock all questions in review mode
        const lockedQuestionsObj = {};
        selectedAnswersData.forEach((answer) => {
          if (answer && answer.question_id) {
            lockedQuestionsObj[answer.question_id] = true;
          }
        });
        setLockedQuestions(lockedQuestionsObj);

        // Always show solution in review mode
        setShowSolution(true);
      } else {
        // Initialize test progress
        const { data: progressData, error: progressError } = await supabase
          .from("test_progress")
          .insert({
            user_id: supabaseUserId,
            passage_index: 0,
            question_index: 0,
            elapsed_time: 0,
          })
          .select()
          .single();

        if (progressError) throw progressError;
      }

      // Set initial passage and question indices
      setCurrentPassageIndex(0);
      setCurrentQuestionIndex(0);
      updateCurrentPassageTime();
    } catch (error) {
      console.error("Error fetching test data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTestData();
    loadUserMemory();
  }, [testId, isReviewMode]);

  useEffect(() => {
    if (isReviewMode) {
      setShowSolution(true);
    }
  }, [isReviewMode]);

  // **New useEffect to reset showSolution when question changes**
  useEffect(() => {
    if (!isReviewMode) {
      setShowSolution(false);
    }
  }, [currentPassageIndex, currentQuestionIndex, isReviewMode]);

  // Add this function after other helper functions
  const loadUserMemory = async () => {
    if (!session?.user?.id) return;

    try {
      const { data, error } = await supabase
        .from("user_profiles")
        .select("memory")
        .eq("user_id", session.user.id)
        .single();

      if (error) throw error;
      setUserMemory(data.memory || []);
      console.log("Loaded user memory:", data.memory);
    } catch (error) {
      console.error("Error loading user memory:", error);
    }
  };

  // Add this function to process and update memories
  const processAndUpdateMemory = async () => {
    if (!session?.user?.id || isProcessingMemory) return;
    setIsProcessingMemory(true);

    try {
      // Prepare context with test performance data
      const testPerformance = {
        totalQuestions: getTotalQuestions(),
        correctAnswers: Object.entries(selectedAnswers).reduce(
          (sum, [questionId, answerIndex]) => {
            const question = passages
              .flatMap((p) => p.new_questions)
              .find((q) => q.id === parseInt(questionId));
            return (
              sum +
              (indexToLabel(answerIndex) === question?.correct_answer ? 1 : 0)
            );
          },
          0,
        ),
        //
        flaggedQuestions: flaggedQuestions,
        confidenceRatings: Object.entries(confidenceRatings).reduce(
          (acc, [qId, rating]) => {
            acc[rating] = (acc[rating] || 0) + 1;
            return acc;
          },
          {},
        ),
        chatHistory: Object.entries(messageHistory)
          .map(
            ([questionId, messages]) =>
              `Question ${questionId} chat history:\n${messages
                .map((msg) => `${msg.role}: ${msg.content}`)
                .join("\n")}`,
          )
          .join("\n\n"),
      };
      console.log("Test performance data:", testPerformance);

      const memoryPrompt = `You are an AI tutor analyzing a student's MCAT test session.
  
  Current memories about this student:
  ${userMemory.map((m) => `- ${m}`).join("\n")}
  
  Test performance data:
  - Total questions: ${testPerformance.totalQuestions}
  - Correct question numbers: ${testPerformance.correctAnswers}
  - Flagged question numbers: ${testPerformance.flaggedQuestions}
  - Confidence distribution: ${JSON.stringify(testPerformance.confidenceRatings)}
  
  Chat interactions from this session:
  ${testPerformance.chatHistory.map((msg) => `- ${msg}`).join("\n")}
  
  Based on this information and existing memories:
  1. Analyze the student's performance patterns
  2. Identify key behaviors or habits
  3. Evaluate confidence vs. performance correlation
  4. Review chat interactions for learning style indicators
  
  Output a one very concise sentence summary of NEW important memories to keep. Include only very significant insights that will help you with future tutoring sessions for this student.`;

      const result = await model.generateContent(memoryPrompt);
      const response = result.response;
      let newMemories = [];
      console.log("AI memory response:", response.text());

      try {
        newMemories = response.text();
      } catch (e) {
        console.error("Error parsing AI memory response:", e);
        newMemories = "";
      }

      // Combine existing and new memories
      const combinedMemories = [...userMemory];
      // newMemories.forEach((memory) => {
      //   if (!combinedMemories.includes(memory)) {
      if (newMemories) combinedMemories.push(newMemories);
      //   }
      // });

      // If we have too many memories, ask AI to prioritize
      if (combinedMemories.length > 10) {
        const prioritizePrompt = `Given these memories about the student:
  ${combinedMemories.map((m) => `- ${m}`).join("\n")}
  
  Select and output a JSON array of the 10 most important memories to keep, prioritizing:
  1. Recent significant patterns
  2. Core learning style insights
  3. Consistent behavioral patterns
  4. Key areas of strength/weakness`;

        const prioritizeResult = await model.generateContent(prioritizePrompt);
        const prioritizedMemories = JSON.parse(
          prioritizeResult.response
            .text()
            .replace(/```json|```/g, "")
            .trim(),
        );

        // Update database with prioritized memories
        const { error: updateError } = await supabase
          .from("user_profiles")
          .update({ memory: prioritizedMemories })
          .eq("user_id", session.user.id);

        if (updateError) throw updateError;
        setUserMemory(prioritizedMemories);
      } else {
        // Update database with combined memories
        const { error: updateError } = await supabase
          .from("user_profiles")
          .update({ memory: combinedMemories })
          .eq("user_id", session.user.id);

        if (updateError) throw updateError;
        setUserMemory(combinedMemories);
      }
    } catch (error) {
      console.error("Error processing memory:", error);
    } finally {
      setIsProcessingMemory(false);
    }
  };

  // Add this helper function near the top of the file, after the imports
  const indexToLabel = (index) => {
    return String.fromCharCode(65 + index); // Converts 0 to 'A', 1 to 'B', etc.
  };

  const labelToIndex = (label) => {
    return label.charCodeAt(0) - 65; // Converts 'A' to 0, 'B' to 1, etc.
  };

  // Update the handleAnswerSelect function
  const handleAnswerSelect = (questionId, choiceIndex) => {
    if (isReviewMode || lockedQuestions[questionId]) return;

    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: choiceIndex,
    }));

    // If confidence hasn't been set yet, focus on the confidence section
    if (!confidenceRatings[questionId]) {
      // Small delay to ensure the confidence UI has rendered
      setTimeout(() => {
        const confidenceContainer = document.getElementById(`confidence-${questionId}`);
        if (confidenceContainer) {
          confidenceContainer.focus();
        }
      }, 50);
    }
  };

  // Timer Effect
  useEffect(() => {
    let interval = null;
    if (!isReviewMode && isTimerRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerRunning, isReviewMode]);

  // Start timer when test is loaded
  useEffect(() => {
    if (!isReviewMode && passages.length > 0) {
      setIsTimerRunning(true);
    }
  }, [passages, isReviewMode]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const toggleTimer = () => {
    setIsTimerRunning((prev) => !prev);
  };

  // Handle Show Solution
  const handleShowSolution = () => {
    if (!currentQuestion) return;

    if (!showSolution) {
      if (selectedAnswers[currentQuestion.id] === undefined) {
        return;
      }
      setLockedQuestions((prev) => ({
        ...prev,
        [currentQuestion.id]: true,
      }));
    }

    setShowSolution((prev) => !prev);
  };

  // Handle Navigation
  const handleNextQuestion = () => {
    if (
      currentPassage &&
      currentQuestionIndex < currentPassage.new_questions.length - 1
    ) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else if (currentPassageIndex < passages.length - 1) {
      setCurrentPassageIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    }
    updateCurrentPassageTime();
    setShowSolution(false);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    } else if (currentPassageIndex > 0) {
      setCurrentPassageIndex((prev) => prev - 1);
      setCurrentQuestionIndex(
        passages[currentPassageIndex - 1].new_questions.length - 1,
      );
    }
    updateCurrentPassageTime();
    setShowSolution(false);
    console.log(
      "Moving to previous question, new ID:",
      passages[currentPassageIndex]?.new_questions[currentQuestionIndex - 1]
        ?.id,
      currentQuestionIndex,
    );
  };

  const handlePreviousPassage = () => {
    if (currentPassageIndex > 0) {
      updateCurrentPassageTime();
      setCurrentPassageIndex((prev) => prev - 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  const handleNextPassage = () => {
    if (currentPassageIndex < passages.length - 1) {
      updateCurrentPassageTime();
      // log all passage time
      console.log("Passage elapsed times:", passageElapsedTimes);
      setCurrentPassageIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
      setShowSolution(false);
    }
  };

  // Handle Highlight and Strikethrough via Hotkeys
  // These functions are now properly defined with handleMarkup
  // (Already defined above)

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (passages.length === 0) {
    console.log("No passages available. Current state:", {
      passages,
      currentPassageIndex,
      currentQuestionIndex,
    });
    return (
      <div>
        No passages available in the database. Check console for more
        information.
      </div>
    );
  }

  if (
    !passages[currentPassageIndex] ||
    !passages[currentPassageIndex].new_questions[currentQuestionIndex]
  ) {
    console.log("No current passage or question. Current state:", {
      passages,
      currentPassageIndex,
      currentQuestionIndex,
      currentPassage: passages[currentPassageIndex],
      currentQuestion:
        passages[currentPassageIndex]?.new_questions?.[currentQuestionIndex],
    });
    return (
      <div>
        No passages or questions available. Please check the console for more
        information.
      </div>
    );
  }

  if (!currentQuestion) {
    return (
      <div>
        No question data available. Please check the console for more
        information.
      </div>
    );
  }

  // Handle Markups
  const handleClearMarkups = () => setMarkups({});

  // Helper function to get markups for a specific element
  const getMarkupsForElement = (elementId) => {
    return (markups[elementId] || []).sort(
      (a, b) => a.range.start - b.range.start,
    );
  };

  // Updated parseChoiceText to extract label and text
  const parseChoiceText = (text) => {
    const regex = /^([A-Z])\.\s*(.*?)(?:\s*\((\d+)%\))?$/;
    const match = text.match(regex);
    if (match) {
      return {
        label: match[1],
        text: match[2].trim(),
        percentage: match[3] ? match[3] : null,
      };
    }
    return {
      label: "",
      text: text,
      percentage: null,
    };
  };

  // Render with Markup Function
  const renderWithMarkup = (text, elementId, type, baseOffset = 0) => {
    if (!text) return null;

    // Get markups for this element
    const elementMarkups = getMarkupsForElement(elementId) || [];

    // Check if this is a roman numeral question
    const lines = text.split("\n");
    const isRomanNumeralQuestion =
      elementId.startsWith("question-") &&
      lines.length >= 5 &&
      lines[2]?.trim().startsWith("I.") &&
      lines[3]?.trim().startsWith("II.") &&
      lines[4]?.trim().startsWith("III.");

    // For roman numeral questions, render each line with proper formatting AND markups
    if (isRomanNumeralQuestion) {
      let currentOffset = 0;
      return (
        <div className="whitespace-pre-wrap">
          {lines.map((line, index) => {
            // Calculate the base offset for this line
            const lineOffset = currentOffset;
            currentOffset += line.length + 1; // +1 for the newline character

            // Process markups for this line
            let result = [];
            let lastIndex = lineOffset;
            const lineMarkups = elementMarkups
              .filter(
                (markup) =>
                  markup.range.start >= lineOffset &&
                  markup.range.start < lineOffset + line.length,
              )
              .sort((a, b) => a.range.start - b.range.start);

            for (let i = 0; i < line.length; i++) {
              const currentOffset = lineOffset + i;
              const activeMarkups = lineMarkups.filter(
                (markup) =>
                  markup.range.start <= currentOffset &&
                  markup.range.end > currentOffset,
              );

              const content = line[i];
              result.push(
                <span
                  key={`char-${currentOffset}`}
                  data-offset={currentOffset}
                  data-element-id={elementId}
                  className={`
                    ${activeMarkups.some((m) => m.type === "highlight") ? "bg-yellow-200" : ""}
                    ${activeMarkups.some((m) => m.type === "strikethrough") ? "line-through" : ""}
                  `}
                >
                  {content}
                </span>,
              );
              lastIndex = currentOffset + 1;
            }

            const lineContent = result.length > 0 ? result : line;

            // Adjust the spacing between question and roman numerals
            if (index === 0)
              return (
                <div key="question" className="mb-2">
                  {lineContent}
                </div>
              );
            if (index === 1) return null; // Skip the empty line between question and roman numerals

            if (index >= 2 && index <= 4) {
              return (
                <div key={`roman-${index}`} className="pl-8 mb-1">
                  {lineContent}
                </div>
              );
            }
            return <div key={`line-${index}`}>{lineContent}</div>;
          })}
        </div>
      );
    }

    // Handle regular text with markups
    let result = [];
    let lastIndex = baseOffset;
    const sortedMarkups = elementMarkups.sort(
      (a, b) => a.range.start - b.range.start,
    );

    for (let i = 0; i < text.length; i++) {
      const currentOffset = baseOffset + i;
      const activeMarkups = sortedMarkups.filter(
        (markup) =>
          markup.range.start <= currentOffset &&
          markup.range.end > currentOffset,
      );

      const content = text[i];
      result.push(
        <span
          key={`char-${currentOffset}`}
          data-offset={currentOffset}
          data-element-id={elementId}
          className={`
            ${activeMarkups.some((m) => m.type === "highlight") ? "bg-yellow-200" : ""}
            ${activeMarkups.some((m) => m.type === "strikethrough") ? "line-through" : ""}
          `}
        >
          {content}
        </span>,
      );
      lastIndex = currentOffset + 1;
    }

    return <span className="whitespace-pre-wrap">{result}</span>;
  };

  // Handle Flagging Questions
  const handleFlagForReview = () => {
    if (!currentQuestion) return;
    setFlaggedQuestions((prev) =>
      prev.includes(currentQuestion.id)
        ? prev.filter((id) => id !== currentQuestion.id)
        : [...prev, currentQuestion.id],
    );
  };

  // Handle Ending Test
  const handleEndTest = () => {
    setShowEndTestConfirmation(true);
  };

  const confirmEndTest = async () => {
    setIsEndingTest(true); // Start loading
    setShowEndTestConfirmation(false);
    setShowTestSummary(true);
  };

  const cancelEndTest = () => {
    setShowEndTestConfirmation(false);
  };

  // Add this function before finalizeTest
  const generateTestAnalysis = async (data) => {
    try {
      const prompt = `
      As an MCAT tutor, analyze this CARS test performance and provide personalized feedback:

      Test Statistics:
      - Score: ${data.score}%
      - Questions: ${data.totalQuestions}
      - Correct Answers: ${data.correctAnswers}
      - Flagged Questions: ${data.flaggedQuestions}
      - Confidence Ratings: High: ${data.confidenceData.High}, Medium: ${data.confidenceData.Medium}, Low: ${data.confidenceData.Low}

      Provide three sections:
      1. Strengths (2-3 bullet points)
      2. Areas for Improvement (2-3 bullet points)
      3. Key Recommendations (3 specific, actionable tips)

      End with a brief encouraging message.
      Format the response as JSON with sections: strengths, improvements, recommendations, encouragement.`;

      const result = await model.generateContent(prompt);
      const analysisText = result.response.text();
      return JSON.parse(analysisText.replace(/```json|```/g, ""));
    } catch (error) {
      console.error("Error generating analysis:", error);
      return null;
    }
  };

  // Finalize Test Submission
  const finalizeTest = async () => {
    setShowTestSummary(false);

    if (!session || !testId) {
      console.error("User or testId is missing");
      return;
    }

    if (!passages || passages.length === 0) {
      console.error("No passages found");
      return;
    }

    try {
      // Update the time for the current passage before finalizing
      updateCurrentPassageTime();

      const supabaseUserId = session.user.id;

      // Calculate the score
      const totalQuestions = getTotalQuestions();
      const correctAnswers = Object.entries(selectedAnswers).reduce(
        (sum, [questionId, answerIndex]) => {
          if (answerIndex === undefined || answerIndex === null) return sum;

          const question = passages
            .flatMap((p) => p.new_questions)
            .find((q) => q.id === parseInt(questionId));

          if (!question) return sum;

          return (
            sum +
            (indexToLabel(answerIndex) === question.correct_answer ? 1 : 0)
          );
        },
        0,
      );

      const score = Math.round((correctAnswers / totalQuestions) * 100);

      console.log("Calculated score:", score);

      // Prepare analysis data
      const analysisData = {
        score,
        totalQuestions,
        correctAnswers,
        flaggedQuestions: flaggedQuestions.length,
        confidenceData: {
          High: Object.values(confidenceRatings).filter((r) => r === "High")
            .length,
          Medium: Object.values(confidenceRatings).filter((r) => r === "Medium")
            .length,
          Low: Object.values(confidenceRatings).filter((r) => r === "Low")
            .length,
        },
      };

      // Generate AI analysis
      const aiSummary = await generateTestAnalysis(analysisData);

      // Format passage elapsed times in the same order as passage_ids
      const orderedPassageElapsedTimes = passages.map(
        (passage) => passageElapsedTimes[passage.id] || 0,
      );

      // Update the test record with all the test progress data
      const { error: testUpdateError } = await supabase
        .from("tests_newest")
        .update({
          score: score,
          ai_summary: aiSummary,
          elapsed_time: elapsedTime,
          passage_elapsed_time: orderedPassageElapsedTimes, // Add this line
        })
        .eq("id", testId);

      if (testUpdateError) {
        console.error("Error updating test record:", testUpdateError);
        throw testUpdateError;
      }

      // Prepare data for test_answers with validation
      const testAnswersData = [];

      passages.forEach((passage) => {
        if (!passage || !passage.id) return;

        const passageMarkups = markups[`passage-${passage.id}`] || [];

        passage.new_questions.forEach((question) => {
          if (!question || !question.id) return;

          const questionId = question.id;
          const selectedAnswer =
            selectedAnswers[questionId] !== undefined &&
            selectedAnswers[questionId] !== null
              ? indexToLabel(selectedAnswers[questionId])
              : null;

          const isCorrect = selectedAnswer === question.correct_answer;
          const confidenceRating = confidenceRatings[questionId] || null;
          const flagged = flaggedQuestions.includes(questionId);

          let questionMarkups = [];
          let choiceMarkups = [];

          try {
            questionMarkups = markups[`question-${questionId}`] || [];
            choiceMarkups = question.new_options.map(
              (_, index) => markups[`choice-${questionId}-${index}`] || [],
            );
          } catch (e) {
            console.error(
              "Error processing markups for question:",
              questionId,
              e,
            );
          }

          const answerData = {
            test_id: testId,
            question_id: questionId,
            passage_id: passage.id,
            selected_answer: selectedAnswer,
            is_correct: isCorrect,
            confidence_rating: confidenceRating,
            flagged: flagged,
            question_markups: JSON.stringify(questionMarkups),
            choice_markups: JSON.stringify(choiceMarkups),
            passage_markups: JSON.stringify(passageMarkups),
          };

          testAnswersData.push(answerData);
        });
      });

      if (testAnswersData.length === 0) {
        throw new Error("No valid test answers to save");
      }

      // Insert test answers into the database
      const { error: insertTestAnswersError } = await supabase
        .from("test_answers_newest")
        .insert(testAnswersData);

      if (insertTestAnswersError) {
        console.error("Error inserting test answers:", insertTestAnswersError);
        throw insertTestAnswersError;
      }

      // Process and update memory before finalizing test
      await processAndUpdateMemory();

      // Navigate back to the dashboard
      navigate(`/test/${testId}/summary`);
    } catch (error) {
      console.error("Error finalizing test:", error);
      alert(
        `An error occurred while saving your test results: ${error.message}`,
      );
    }
  };

  const finalizeTestReview = async () => {
    setShowTestSummary(false);

    if (!session || !testId) {
      console.error("User or testId is missing");
      return;
    }

    if (!passages || passages.length === 0) {
      console.error("No passages found");
      return;
    }

    try {
      passages.forEach((passage) => {
        if (!passage || !passage.id) return;

        const passageMarkups = markups[`passage-${passage.id}`] || [];

        const updateQuestions = async () => {
          for (const question of passage.new_questions) {
            if (!question || !question.id) continue;

            const questionId = question.id;
            const selectedAnswer =
              selectedAnswers[questionId] !== undefined &&
              selectedAnswers[questionId] !== null
                ? indexToLabel(selectedAnswers[questionId])
                : null;

            const isCorrect = selectedAnswer === question.correct_answer;
            const confidenceRating = confidenceRatings[questionId] || null;
            const flagged = flaggedQuestions.includes(questionId);

            let questionMarkups = [];
            let choiceMarkups = [];

            try {
              questionMarkups = markups[`question-${questionId}`] || [];
              choiceMarkups = question.new_options.map(
                (_, index) => markups[`choice-${questionId}-${index}`] || [],
              );
            } catch (e) {
              console.error(
                "Error processing markups for question:",
                questionId,
                e,
              );
            }

            // get row id from selectedBigAnswer that matches the test_id and question_id
            const rowId = selectedBigAnswer.find(
              (answer) =>
                answer.test_id === testId && answer.question_id === questionId,
            ).id;

            const answerData = {
              test_id: testId,
              question_id: questionId,
              passage_id: passage.id,
              selected_answer: selectedAnswer,
              is_correct: isCorrect,
              confidence_rating: confidenceRating,
              flagged: flagged,
              question_markups: JSON.stringify(questionMarkups),
              choice_markups: JSON.stringify(choiceMarkups),
              passage_markups: JSON.stringify(passageMarkups),
            };

            const { error: updateTestAnswersError } = await supabase
              .from("test_answers_newest")
              .update(answerData)
              .eq("id", rowId);

            if (updateTestAnswersError) {
              console.error(
                "Error updating test answers:",
                updateTestAnswersError,
              );
            }
          }
        };

        updateQuestions();
      });
      // Navigate back to the dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error("Error finalizing test:", error);
      alert(
        `An error occurred while saving your test results: ${error.message}`,
      );
    }
  };

  const getTotalIncompleteQuestions = () => {
    return passages.reduce(
      (total, passage) =>
        total +
        passage.new_questions.filter((q) => selectedAnswers[q.id] === undefined)
          .length,
      0,
    );
  };

  // Handle Feedback Submission
  const handleSubmitFeedback = async () => {
    if (feedbackText.trim() === "") return;

    try {
      const supabaseUserId = session.user.id;

      // Insert feedback into the 'feedback' table
      const { error: insertError } = await supabase
        .from("feedback_new")
        .insert([
          {
            user_id: supabaseUserId,
            test_id: testId, // Optional: Include if feedback is tied to a specific test
            feedback_text: feedbackText,
          },
        ]);

      if (insertError) throw insertError;

      // Success feedback to user
      alert("Feedback submitted. Thank you!");
      setFeedbackText("");
      setShowFeedbackDialog(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert(
        `An error occurred while submitting your feedback: ${error.message}`,
      );
    }
  };

  // Add this function after other helper functions
  const updateCurrentPassageTime = () => {
    if (!isReviewMode && currentPassage) {
      const currentTime = Date.now();
      const elapsedTime = Math.round((currentTime - passageStartTime) / 1000); // Convert to seconds

      setPassageElapsedTimes((prev) => ({
        ...prev,
        [currentPassage.id]: (prev[currentPassage.id] || 0) + elapsedTime,
      }));

      setPassageStartTime(currentTime);
      console.log("current passage elapsed times:", passageElapsedTimes);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 font-[Arial]">
      {/* Header */}
      <header className="bg-[#0066cc] text-white p-2 flex justify-between items-center">
        <h1 className="text-lg font-bold">
          {isReviewMode ? "Test Review" : "Medical College Admission Test"} -{" "}
          {session?.user?.name || "Guest"}
        </h1>
        <div className="flex items-center space-x-4">
          <Button
            variant="ghost"
            size="sm"
            onClick={toggleTimer}
            className="text-white hover:bg-white hover:text-[#0066cc] transition-colors duration-200 flex items-center space-x-2"
          >
            <Clock className="w-4 h-4 mr-1" />
            <span>{formatTime(elapsedTime)}</span>
            {!isTimerRunning && <Play className="w-4 h-4 ml-1" />}
          </Button>
          <span className="text-sm">
            Question {getCurrentQuestionNumber()} of {getTotalQuestions()}
          </span>
        </div>
      </header>

      {/* Main Content */}
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
            <p className="mt-4 text-gray-600">Loading test data...</p>
          </div>
        </div>
      ) : (
        <>
          {/* Toolbar */}
          <div className="bg-[#4a90e2] text-white p-2 flex justify-between items-center">
            <div className="flex space-x-4">
              <Button
                variant="ghost"
                size="sm"
                onClick={handleHighlight}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Highlighter className="w-4 h-4 mr-1" />
                Highlight
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleStrikethrough}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Scissors className="w-4 h-4 mr-1" />
                Strikethrough
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={handleClearMarkups}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <Eraser className="w-4 h-4 mr-1" />
                Clear
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowFeedbackDialog(true)}
                className="text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200"
              >
                <MessageSquare className="w-4 h-4 mr-1" />
                Feedback
              </Button>
            </div>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleFlagForReview}
              className={`text-white hover:bg-white hover:text-[#4a90e2] transition-colors duration-200 ${
                flaggedQuestions.includes(currentQuestion?.id)
                  ? "bg-white !text-[#4a90e2]"
                  : ""
              }`}
            >
              <Flag
                className={`w-4 h-4 mr-1 ${flaggedQuestions.includes(currentQuestion?.id) ? "text-yellow-400" : ""}`}
              />
              {flaggedQuestions.includes(currentQuestion?.id)
                ? "Flagged"
                : "Flag for Review"}
            </Button>
          </div>

          {/* Main Content */}
          <div className="flex-1 flex overflow-hidden" ref={contentRef}>
            {/* Passage Section */}
            <div className="w-1/2 p-4 overflow-y-auto">
              <div className="p-4">
                {/* Passage Navigation */}
                <div className="flex justify-between items-center mb-4">
                  <span
                    onClick={handlePreviousPassage}
                    className={`cursor-pointer ${currentPassageIndex === 0 ? "text-gray-400" : "text-blue-600 hover:text-blue-800"}`}
                  >
                    {currentPassageIndex > 0 && "← Previous Passage"}
                  </span>
                  <span
                    onClick={handleNextPassage}
                    className={`cursor-pointer ${currentPassageIndex === passages.length - 1 ? "text-gray-400" : "text-blue-600 hover:text-blue-800"}`}
                  >
                    {currentPassageIndex < passages.length - 1 &&
                      "Next Passage →"}
                  </span>
                </div>

                {/* Passage Title */}
                <h2 className="text-xl font-bold mb-4">
                  {passages.length > 0 && currentPassage && (
                    <>
                      Passage {currentPassageIndex + 1} (Questions{" "}
                      {
                        getQuestionRangeForPassage(currentPassageIndex)
                          .startingQuestion
                      }{" "}
                      -{" "}
                      {
                        getQuestionRangeForPassage(currentPassageIndex)
                          .endingQuestion
                      }
                      )
                    </>
                  )}
                </h2>

                {/* Passage Content */}
                <div className="whitespace-pre-wrap font-['Helvetica','Arial',sans-serif] text-[17px] leading-relaxed">
                  {currentPassage.passage_text
                    .split("\n")
                    .map((paragraph, index, array) => (
                      <React.Fragment key={index}>
                        <div className="space-y-0.5">
                          <div className="indent-8">
                            {renderWithMarkup(
                              paragraph
                                .replace(/\.\"  +/g, '." ')
                                .replace(/\!\"  +/g, '!" ')
                                .replace(/\?\"  +/g, '?" ')
                                .replace(/\.  +/g, ". ")
                                .replace(/\!  +/g, "! ")
                                .replace(/\?  +/g, "? "),
                              `passage-${currentPassage.id}`,
                              "passage",
                              array
                                .slice(0, index)
                                .reduce((sum, p) => sum + p.length + 1, 0),
                            )}
                          </div>
                        </div>
                        {index < array.length - 1 && (
                          <div className="h-[0.50rem]" />
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>

            {/* Question Section */}
            <div className="w-1/2 p-4 overflow-y-auto">
              <div className="p-4">
                {/* Question Title */}
                <h3 className="text-lg font-bold mb-4">
                  Question{" "}
                  {getActualQuestionNumber(
                    currentPassageIndex,
                    currentQuestionIndex,
                  )}
                </h3>

                {/* Question Text */}
                <p className="mb-4 font-['Helvetica','Arial',sans-serif] text-[17px] leading-relaxed">
                  {renderWithMarkup(
                    currentQuestion.text,
                    `question-${currentQuestion.id}`,
                    "question",
                  )}
                </p>

                {/* Answer Choices */}
                {currentQuestion && currentQuestion.new_options && (
                  <div className="space-y-4">
                    <RadioGroup
                      key={`radio-group-${currentQuestion.id}`}
                      value={
                        selectedAnswers[currentQuestion.id] !== undefined
                          ? selectedAnswers[currentQuestion.id]?.toString()
                          : undefined
                      }
                      onValueChange={(value) =>
                        handleAnswerSelect(currentQuestion.id, parseInt(value))
                      }
                      className="space-y-2"
                    >
                      {selectedAnswers[currentQuestion.id] === undefined &&
                        isReviewMode && (
                          <div className="mb-4 p-3 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <AlertCircle
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-sm">
                                  This question was not answered during the test.
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      {currentQuestion.new_options
                        .filter((choice) => choice && choice.text)
                        .map((choice, index) => {
                          const label = choice.label;
                          const text = choice.text;
                          const isCorrect =
                            indexToLabel(index) ===
                            currentQuestion.correct_answer;
                          const isSelected =
                            selectedAnswers[currentQuestion.id] === index;
                          const shouldShowSolution = showSolution || isReviewMode;

                          return (
                            <div
                              key={index}
                              className={cn(
                                "relative flex items-center p-3 rounded-lg transition-colors",
                                // Only show borders and backgrounds for selected/correct answers
                                !shouldShowSolution && isSelected
                                  ? "border-2 border-blue-500 bg-blue-50"
                                  : "hover:bg-gray-50",
                                shouldShowSolution && isCorrect
                                  ? "border-2 border-green-500 bg-green-50"
                                  : "",
                                shouldShowSolution && isSelected && !isCorrect
                                  ? "border-2 border-red-500 bg-red-50"
                                  : "",
                              )}
                            >
                              <RadioGroupItem
                                value={index.toString()}
                                id={`q${currentQuestion.id}-choice${index}`}
                                checked={isSelected}
                                disabled={
                                  isReviewMode ||
                                  lockedQuestions[currentQuestion.id]
                                }
                                className="h-4 w-4 mr-3 focus:outline-none focus:ring-0 focus:ring-offset-0"
                              />
                              <Label
                                htmlFor={`q${currentQuestion.id}-choice${index}`}
                                className="flex-1 cursor-pointer text-sm"
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center space-x-2">
                                    <span className="font-semibold text-gray-900 select-none">
                                      {label}.
                                    </span>
                                    <span className="text-gray-700">
                                      {renderWithMarkup(
                                        text,
                                        `choice-${currentQuestion.id}-${index}`,
                                        "choice",
                                      )}
                                    </span>
                                  </div>
                                  {shouldShowSolution && (
                                    <span className="inline-flex items-center ml-2 text-xs whitespace-nowrap">
                                      {isCorrect && (
                                        <span className="text-green-600 flex items-center">
                                          <CheckCircle className="w-3 h-3 mr-1" />
                                          Correct
                                        </span>
                                      )}
                                      {isSelected && !isCorrect && (
                                        <span className="text-red-600 flex items-center ml-2">
                                          <XCircle className="w-3 h-3 mr-1" />
                                          Your Answer
                                        </span>
                                      )}
                                    </span>
                                  )}
                                </div>
                              </Label>
                            </div>
                          );
                        })}
                    </RadioGroup>

                    {/* Integrated Confidence Rating */}
                    <div 
                      id={`confidence-${currentQuestion?.id}`}
                      tabIndex={-1}
                      className={`
                        flex items-center gap-2 text-sm
                        transition-all duration-0
                        ${selectedAnswers[currentQuestion?.id] !== undefined ? 'opacity-100' : 'opacity-0 pointer-events-none h-0'}
                      `}
                    >
                      <span className="text-gray-500">Confidence:</span>
                      <div className="flex gap-1.5">
                        {[
                          {
                            rating: "Low",
                            key: "1",
                            colors: {
                              active: "bg-red-500 border-red-500",
                              inactive: "border-red-200 hover:border-red-300",
                              text: "text-red-600"
                            }
                          },
                          {
                            rating: "Medium",
                            key: "2",
                            colors: {
                              active: "bg-yellow-500 border-yellow-500",
                              inactive: "border-yellow-200 hover:border-yellow-300",
                              text: "text-yellow-600"
                            }
                          },
                          {
                            rating: "High",
                            key: "3",
                            colors: {
                              active: "bg-green-500 border-green-500",
                              inactive: "border-green-200 hover:border-green-300",
                              text: "text-green-600"
                            }
                          }
                        ].map(({ rating, key, colors }) => (
                          <button
                            key={rating}
                            onClick={() => handleConfidenceRating(rating)}
                            className={`
                              px-2.5 py-0.5 rounded-full text-xs font-medium
                              transition-all duration-200 border
                              ${confidenceRatings[currentQuestion?.id] === rating
                                ? `${colors.active} text-white`
                                : `${colors.inactive} ${colors.text} bg-white`
                              }
                              hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-500
                            `}
                          >
                            {rating}
                          </button>
                        ))}
                        <div className="text-xs text-gray-400 ml-1 flex items-center">
                          {[1,2,3].map(num => (
                            <span key={num} className="ml-1">[{num}]</span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Action Buttons */}
                <div className="mt-4 space-x-2 flex items-center">
                  {!isReviewMode && (
                    <Button
                      onClick={handleShowSolution}
                      disabled={
                        !showSolution &&
                        selectedAnswers[currentQuestion?.id] === undefined
                      }
                      className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed h-9 px-4" // Added explicit height and padding
                    >
                      {showSolution ? "Hide Solution" : "Show Solution"}
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setShowChat(!showChat);
                      setShowSolution(false);
                    }}
                    className={`
                      relative overflow-hidden
                      ${showChat ? 'bg-[#0066cc]' : 'bg-[#0066cc]'}
                      text-white
                      hover:shadow-lg
                      transform transition-all duration-200
                      border border-blue-400/20
                      flex items-center gap-2
                      ${showChat ? 'animate-none' : 'hover:scale-[1.02]'}
                      h-9 px-4 /* Added explicit height and padding to match */
                    `}
                  >
                    {/* Subtle gradient overlay */}
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10"></div>
                    
                    {/* Sparkle effect */}
                    <div className="absolute inset-0">
                      <div className={`
                        absolute top-0 left-0 w-full h-full
                        ${!showChat ? 'animate-sparkle' : ''}
                        bg-gradient-to-r from-transparent via-white/10 to-transparent
                      `}></div>
                    </div>

                    <div className="flex items-center gap-2 z-10">
                      <div className={`
                        w-2 h-2 rounded-full
                        ${showChat 
                          ? 'bg-white animate-pulse' 
                          : 'bg-blue-200 animate-[pulse_1.5s_ease-in-out_infinite]'
                        }
                      `}/>
                      <MessageCircle className="w-4 h-4" />
                      <span>{showChat ? 'Hide AI' : 'Ask AI'}</span>
                      {!showChat && (
                        <span className="text-xs bg-white/20 px-1.5 py-0.5 rounded-full">Beta</span>
                      )}
                    </div>
                  </Button>
                </div>

                {showChat && (
                  <div className="mt-6 p-4 bg-blue-50 border border-blue-200 rounded shadow-md">
                    <h4 className="text-lg font-semibold mb-2 text-blue-800 flex items-center justify-between">
                      <div className="flex items-center">
                        <MessageCircle className="w-5 h-5 mr-2" />
                        Chat with UPenguin{" "}
                        <Icon iconNode={penguin} className="ml-1" />
                      </div>
                    </h4>
                    <div className="h-[300px] overflow-y-auto mb-4 p-4 bg-white rounded">
                      {messages.map((message, index) => (
                        <div
                          key={index}
                          className={`mb-4 ${message.role === "user" ? "text-right" : "text-left"}`}
                        >
                          <div
                            className={`inline-block p-3 rounded-lg ${
                              message.role === "user"
                                ? "bg-blue-100 text-blue-900"
                                : "bg-gray-100 text-gray-900"
                            }`}
                          >
                            <div className="flex items-center gap-2 text-sm font-medium mb-1">
                              {message.role === "user" ? (
                                "You"
                              ) : (
                                <>
                                  <Icon
                                    iconNode={penguin}
                                    className="w-4 h-4"
                                  />
                                  <span>UPenguin</span>
                                </>
                              )}
                              <span className="text-xs text-gray-500">
                                {new Date(
                                  message.timestamp,
                                ).toLocaleTimeString()}
                              </span>
                            </div>
                            <p className="text-sm">{message.content}</p>
                          </div>
                        </div>
                      ))}
                      {isTyping && (
                        <div className="text-left">
                          <div className="inline-block p-3 rounded-lg bg-gray-100">
                            <div className="flex items-center gap-2">
                              <Icon iconNode={penguin} className="w-4 h-4" />
                              <div className="flex gap-1">
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <form onSubmit={handleSubmit} className="flex gap-2">
                      <input
                        id="chat-input"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Ask about the passage, question, ..."
                        className="flex-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isTyping}
                      />
                      <Button
                        type="submit"
                        disabled={isTyping || !input.trim()}
                        className={`text-white transition-all duration-200 ${
                          isTyping
                            ? "bg-gray-400"
                            : "bg-[#0066cc] hover:bg-[#0052a3]"
                        }`}
                      >
                        {isTyping ? (
                          <span className="flex items-center gap-2">
                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                            Thinking...
                          </span>
                        ) : (
                          "Send"
                        )}
                      </Button>
                    </form>
                  </div>
                )}

                {/* Unified Explanation Block */}
                {(showSolution || isReviewMode) && (
                  <div className="mt-6 p-4 bg-blue-50 border border-blue-200 rounded shadow-md">
                    <h4 className="text-lg font-semibold mb-2 text-blue-800">
                      Explanation:
                    </h4>
                    <div className="text-gray-700 leading-relaxed">
                      <p className="mb-4 text-blue-700 font-medium">
                        The correct answer is: {currentQuestion.correct_answer}
                      </p>
                      {currentQuestion.explanation
                        ? currentQuestion.explanation
                            .split("\n")
                            .map((paragraph, index, array) => (
                              <React.Fragment key={index}>
                                <div className="space-y-0.5">
                                  {renderWithMarkup(
                                    paragraph,
                                    `question-explanation-${currentQuestion.id}`,
                                    "explanation",
                                    array
                                      .slice(0, index)
                                      .reduce(
                                        (sum, p) => sum + p.length + 1,
                                        0,
                                      ),
                                  )}
                                </div>
                                {index < array.length - 1 && (
                                  <div className="h-[0.35rem]" />
                                )}
                              </React.Fragment>
                            ))
                        : "No explanation available for this question."}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* Footer with Navigation Buttons */}
      <footer className="bg-gray-200 p-2 flex justify-end items-center">
        {isReviewMode ? (
          <div className="w-full grid grid-cols-2 items-center"> {/* Changed from grid-cols-3 */}
            <div>
              <Button
                variant="default"
                onClick={async () => {
                  await finalizeTestReview();
                }}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                Back to Dashboard
              </Button>
            </div>

            <div className="flex justify-end space-x-2">
              <Button
                variant="default"
                onClick={handlePreviousQuestion}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <ChevronLeft className="w-4 h-4 mr-1" /> Previous
              </Button>
              <Button
                variant="default"
                onClick={() => setShowNavigation(true)}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <Menu className="w-4 h-4 mr-1" /> Navigation
              </Button>
              <Button
                variant="default"
                onClick={handleNextQuestion}
                disabled={isLastQuestion()}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3] disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                Next <ChevronRight className="w-4 h-4 ml-1" />
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full grid grid-cols-2 items-center"> {/* Changed from grid-cols-3 */}
            <div>
              <Button
                variant="default"
                onClick={handleEndTest}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                End Session
              </Button>
            </div>

            <div className="flex justify-end space-x-2">
              <Button
                variant="default"
                onClick={handlePreviousQuestion}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <ChevronLeft className="w-4 h-4 mr-1" /> Previous
              </Button>
              <Button
                variant="default"
                onClick={() => setShowNavigation(true)}
                className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              >
                <Menu className="w-4 h-4 mr-1" /> Navigation
              </Button>
              {isLastQuestion() ? (
                <Button
                  variant="default"
                  onClick={handleEndTest}
                  className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
                >
                  End Test
                </Button>
              ) : (
                <Button
                  variant="default"
                  onClick={handleNextQuestion}
                  className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
                >
                  Next <ChevronRight className="w-4 h-4 ml-1" />
                </Button>
              )}
            </div>
          </div>
        )}
      </footer>

      {/* Navigation Dialog */}
      <Dialog
        open={showNavigation}
        onOpenChange={setShowNavigation}
        id="navigation-dialog"
      >
        <DialogContent
          className="sm:max-w-[800px]"
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "800px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle className="flex items-center justify-between">
              <div className="flex items-center">
                <Menu className="w-5 h-5 mr-2" />
                Navigation - Select a question to go to it
              </div>
            </DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.new_questions.map((question, questionIndex) => {
                    const actualQuestionNumber = getActualQuestionNumber(
                      passageIndex,
                      questionIndex,
                    );
                    return (
                      <TableRow
                        key={question.id}
                        className={
                          currentPassageIndex === passageIndex &&
                          currentQuestionIndex === questionIndex
                            ? "bg-yellow-100"
                            : ""
                        }
                      >
                        <TableCell>
                          <Button
                            variant="link"
                            onClick={() => {
                              setCurrentPassageIndex(passageIndex);
                              setCurrentQuestionIndex(questionIndex);
                              setShowNavigation(false);
                              updateCurrentPassageTime();
                            }}
                          >
                            Question {actualQuestionNumber}
                          </Button>
                        </TableCell>
                        <TableCell
                          className={
                            selectedAnswers[question.id] !== undefined
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {selectedAnswers[question.id] !== undefined
                            ? "Complete"
                            : "Incomplete"}
                        </TableCell>
                        <TableCell>
                          {flaggedQuestions.includes(question.id) && (
                            <Flag className="h-4 w-4 text-yellow-500" />
                          )}
                        </TableCell>
                        <TableCell>
                          {confidenceRatings[question.id] || "Not set"}
                        </TableCell>
                      </TableRow>
                    );
                  }),
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button
              onClick={() => setShowNavigation(false)}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Feedback Dialog */}
      <Dialog open={showFeedbackDialog} onOpenChange={setShowFeedbackDialog}>
        <DialogContent
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "450px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>Provide Feedback</DialogTitle>
          </DialogHeader>
          <Textarea
            placeholder="Enter your feedback here..."
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <DialogFooter>
            <Button
              onClick={handleSubmitFeedback}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
              disabled={feedbackText.trim() === ""}
            >
              Submit Feedback
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* End Test Confirmation Dialog */}
      <Dialog
        open={showEndTestConfirmation}
        onOpenChange={setShowEndTestConfirmation}
      >
        <DialogContent
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "450px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>End Test Confirmation</DialogTitle>
          </DialogHeader>
          <p>Are you sure you want to end this test?</p>
          <DialogFooter>
            <Button variant="outline" onClick={cancelEndTest}>
              Cancel
            </Button>
            <Button
              onClick={confirmEndTest}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              End Test
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Test Summary Dialog */}
      <Dialog open={showTestSummary} onOpenChange={setShowTestSummary}>
        <DialogContent
          className="sm:max-w-[800px]"
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            boxShadow:
              "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxWidth: "800px",
            maxHeight: "85vh",
            padding: "25px",
          }}
        >
          <DialogHeader>
            <DialogTitle>Test Summary</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[400px] mt-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Question</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Flagged For Review</TableHead>
                  <TableHead>Confidence</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {passages.flatMap((passage, passageIndex) =>
                  passage.new_questions.map((question, questionIndex) => (
                    <TableRow
                      key={question.id}
                      className={
                        currentPassageIndex === passageIndex &&
                        currentQuestionIndex === questionIndex
                          ? "bg-yellow-100"
                          : ""
                      }
                    >
                      <TableCell>
                        Question{" "}
                        {getActualQuestionNumber(passageIndex, questionIndex)}
                      </TableCell>
                      <TableCell
                        className={
                          selectedAnswers[question.id] !== undefined
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {selectedAnswers[question.id] !== undefined
                          ? "Complete"
                          : "Incomplete"}
                      </TableCell>
                      <TableCell>
                        {flaggedQuestions.includes(question.id) && (
                          <Flag className="h-4 w-4 text-yellow-500" />
                        )}
                      </TableCell>
                      <TableCell>
                        {confidenceRatings[question.id] || "Not set"}
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
          </ScrollArea>
          <DialogFooter className="flex justify-between items-center">
            <div>{getTotalIncompleteQuestions()} Unseen/Incomplete</div>
            <Button
              onClick={finalizeTest}
              className="text-white bg-[#0066cc] hover:bg-[#0052a3]"
            >
              End
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <style jsx global>{`
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }

        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 8s ease infinite;
        }

        .bg-grid-white\/10 {
          background-image: linear-gradient(
              to right,
              rgba(255, 255, 255, 0.1) 1px,
              transparent 1px
            ),
            linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.1) 1px,
              transparent 1px
            );
        }

        .bg-grid-8 {
          background-size: 8px 8px;
        }

        /* Roman numeral styling */
        .pl-8 {
          padding-left: 2rem;
          text-indent: -1rem; /* This ensures proper wrapping alignment */
          margin-left: 1rem; /* This compensates for the negative text-indent */
          display: block;
          margin-bottom: 0.5rem;
        }
      `}</style>
      {isEndingTest && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full mx-4">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mb-4"></div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Finalizing Your Test
              </h2>
              <p className="text-gray-600 text-center">
                Please wait while we save your answers and prepare your
                summary...
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
