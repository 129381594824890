import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "../components/ui/button";
import { LogIn, Lock, MessageSquare } from "lucide-react";
import logoImage from "../assets/images/5.svg";

export default function UnauthorizedPage() {
  return (
    <>
      <Helmet>
        <title>Login Required - UPangea MCAT Prep</title>
        <meta
          name="description"
          content="Please log in to access this section of UPangea's MCAT preparation platform."
        />
        <meta name="robots" content="noindex,follow" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50 flex flex-col">
        {/* Header */}
        <header className="px-4 lg:px-6 h-20 flex items-center">
          <Link className="flex items-center justify-center" to="/">
            <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
            <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
              UPangea
            </span>
          </Link>
        </header>

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center px-4">
          <div className="max-w-3xl mx-auto text-center">
            {/* Lock Icon Display */}
            <div className="mb-8">
              <div className="w-64 h-64 mx-auto bg-gradient-to-r from-blue-600/10 to-green-600/10 rounded-full flex items-center justify-center">
                <div className="w-48 h-48 bg-gradient-to-r from-blue-600/20 to-green-600/20 rounded-full flex items-center justify-center">
                  <div className="w-32 h-32 bg-gradient-to-r from-blue-600 to-green-600 rounded-full flex items-center justify-center">
                    <Lock className="w-16 h-16 text-white" />
                  </div>
                </div>
              </div>
            </div>

            {/* Message */}
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Login Required
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              This section requires authentication. Please log in or create an
              account to access UPangea's MCAT preparation resources.
            </p>

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Button
                asChild
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 px-6 py-2 text-lg shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <Link to="/">
                  <LogIn className="w-5 h-5 mr-2" />
                  Log In Now
                </Link>
              </Button>
              <Button
                asChild
                variant="outline"
                className="text-gray-600 border-gray-300 hover:bg-gray-50"
              >
                <Link to="/contact">
                  <MessageSquare className="w-4 h-4 mr-2" />
                  Contact Us
                </Link>
              </Button>
            </div>

            {/* Additional Info */}
            <div className="mt-12 bg-white/50 backdrop-blur-sm rounded-lg p-6 max-w-xl mx-auto">
              <h2 className="font-semibold text-gray-900 mb-2">Why Log In?</h2>
              <ul className="text-gray-600 text-left space-y-2">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2"></div>
                  Access our comprehensive MCAT question bank
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2"></div>
                  Track your progress and performance
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-blue-600 rounded-full mr-2"></div>
                  Get personalized study recommendations
                </li>
              </ul>
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer className="py-6">
          <div className="container mx-auto px-4">
            <div className="text-center text-gray-600">
              <p className="text-sm">
                © 2024 UPangea Inc. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
